import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Input, Row } from "antd";
import moment from "moment";
import { MEMBER_URL } from "../../api/URLs";
import { useNavigate } from "react-router-dom";
import { getDataRequest } from "../../api/serviceMethods";
import TableFetchData from "../../components/data-table/TableFetchData";
import { useTranslation } from "react-i18next";
import DateComponent from "../../components/date/DateComponent";
import { CheckCircleOutlined } from "@ant-design/icons";

const TableUserMember = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [memberData, setMemberData] = useState([]);
    const [dates, setDates] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });
    const navigate = useNavigate();

    const initLoadMember = async () => {
        setLoading(true);
        await getDataRequest(`${MEMBER_URL}`, {
            keyword: keyword.trim(),
            startRegDate: dates ? moment(dates).format("YYYY-MM-DD") : "",
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
        }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setMemberData(res?.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.total,
                    },
                });
            }
        }).catch((err) => {
            setLoading(false);
            console.log("Error", err);
        });
    };

    const handleClickEdit = (item) => {
        navigate("/member/details");
    };

    // Run initial data fetch on component mount
    useEffect(() => {
        initLoadMember();
    }, [tableParams.pagination.current]);

    const tableSettings = {
        appendable: false,
        removable: false,
        bordered: true,
        size: "small",
        pagination: {
            showLessItems: true,
            pageSize: 10,
        },
        columns: [
            {
                title: t('User Avatar'),
                render: (_, member) => (
                    <Avatar 
                        src={member && member.picUrl ? member.picUrl : "https://assets.cdn.filesafe.space/mbSLuyqsQa0R51Fqlx9K/media/6695a278dea9feae1c827540.png"  }
                        style={{
                            height:"35px",
                            width:"35px"
                        }}
                    />
                ),
            },
            {
                title: t('User Id'),
                render: (_, member) => (
                    <label className="text-capitalize">{member && member.firstName}</label>
                ),
            },
            {
                title: "",
                render: (_, member) => (
                    <Row align="middle">
                    <Col>
                      <CheckCircleOutlined style={{ color: "#e72158", marginRight: 8 }} />
                    </Col>
                    <Col className="text-capitalize">{t("Membership trial")}</Col>
                  </Row>
                ),
            },
            {
                title: t('Phone Number'),
                render: (_, member) => (
                    <div className="d-flex">
                        <label className="text-capitalize">
                            {`(${member && member.phone && member.phone.countryCode})`} {member && member.phone && member.phone.number}
                        </label>
                    </div>
                ),
            },
            {
                title: t('Register Time'),
                render: (_, member) => (
                    <label className="text-capitalize">
                        {moment(member && member.dob).format("YYYY-MM-DD")}
                    </label>
                ),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.age - b.age,
            },
            {
                title: t('Last Login Time'),
                dataIndex: 'email',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.age - b.age,
            },
            {
                title: t('Operator'),
                render: (_, member) => (
                    <div className="d-flex" style={{ cursor: "pointer", color: "#0075ff" }} onClick={() => handleClickEdit(member)}>
                        {t("Details")}
                    </div>
                ),
            },
        ],
    };

    return (
        <>
            <div className="w-100" style={{ padding: "0px 25px" }}>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        <Input
                            placeholder={t("Search")}
                            size="large"
                            disabled={loading}
                            loading={loading}
                            className="me-4 col-9"
                            allowClear
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <DateComponent
                            className="col-2"
                            width="50%"
                            placeholder={t("Please select a time or time period")}
                            disabled={loading}
                            value={dates}
                            setValue={setDates}
                        />
                    </div>
                    <div>
                        <Button
                            size="large"
                            onClick={() => {
                                setTableParams({
                                    ...tableParams,
                                    pagination: {
                                        ...tableParams.pagination,
                                        current: 1,
                                    },
                                });
                                initLoadMember();
                            }}
                        >
                            {t('Query')}
                        </Button>

                    </div>
                </div>
            </div>
            <div className="w-100" style={{ padding: "0px 25px" }}>
                <TableFetchData
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    data={memberData}
                    columns={tableSettings.columns}
                    loading={loading}
                    setLoading={setLoading}
                />
            </div>
        </>
    );
};

export default TableUserMember;
