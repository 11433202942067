import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import locatStyle from '../styles/Location.module.css'

export const languages = {
    "af": "Afrikaans",
    "ar": "Arabic",
    "bn-BD": "Bangla",
    "bs-Latn": "Bosnian (Latin)",
    "bg": "Bulgarian",
    "yue": "Cantonese (Traditional)",
    "ca": "Catalan",
    "zh-Hans": "Chinese Simplified",
    "zh-Hant": "Chinese Traditional",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "prs": "Dari",
    "nl": "Dutch",
    "en": "English",
    "et": "Estonian",
    "fj": "Fijian",
    "fil": "Filipino",
    "fi": "Finnish",
    "fr": "French",
    "de": "German",
    "el": "Greek",
    "gu": "Gujarati",
    "ht": "Haitian Creole",
    "he": "Hebrew",
    "hi": "Hindi",
    "mww": "Hmong Daw",
    "hu": "Hungarian",
    "is": "Icelandic",
    "id": "Indonesian",
    "ga": "Irish",
    "it": "Italian",
    "ja": "Japanese",
    "kn": "Kannada",
    "kk": "Kazakh",
    "sw": "Kiswahili",
    "tlh": "Klingon",
    "ko": "Korean",
    "ku": "Kurdish (Central)",
    "kmr": "Kurdish (Northern)",
    "lv": "Latvian",
    "lt": "Lithuanian",
    "mg": "Malagasy",
    "ms": "Malay (Latin)",
    "ml": "Malayalam",
    "mt": "Maltese",
    "mi": "Maori",
    "mr": "Marathi",
    "nb": "Norwegian Bokmål",
    "or": "Odia",
    "ps": "Pashto",
    "fa": "Persian",
    "pl": "Polish",
    "pt": "Portuguese (Brazil)",
    "pt-pt": "Portuguese (Portugal)",
    "pa": "Punjabi (Gurmukhi)",
    "otq": "Querétaro Otomi",
    "ro": "Romanian",
    "ru": "Russian",
    "sm": "Samoan",
    "sr-Cyrl": "Serbian (Cyrillic)",
    "sr-Latn": "Serbian (Latin)",
    "sk": "Slovak",
    "sl": "Slovenian",
    "es": "Spanish",
    "sv": "Swedish",
    "ty": "Tahitian",
    "ta": "Tamil",
    "te": "Telugu",
    "th": "Thai",
    "to": "Tongan",
    "tr": "Turkish",
    "uk": "Ukrainian",
    "ur": "Urdu",
    "vi": "Vietnamese",
    "cy": "Welsh",
    "yua": "Yucatec Maya"
};

class AddLanguageModal extends React.Component {
    render() {
        return (
            <div style={{width: "100%"}}>
                <div style={{display: "flex"}}>
                <Dropdown id="selectcenter" style={{ flex: 1 }}>
                    <Dropdown.Toggle style={{ width: "100%", textAlign: "right" }}>
                        <div style={{ position: "absolute", left: 0, width: "100%", height: "100%", textAlign: "left" }}>
                            <span style={{ marginTop: "auto", marginBottom: "auto", marginLeft: 10 }}>{
                                this.props.selectedLanguages.length === 0 ? 'Please select language' : (
                                    this.props.selectedLanguages.length === 1 ? languages[this.props.selectedLanguages[0]] : (
                                        this.props.selectedLanguages.length + ' Languages'
                                    )
                                )
                            }
                            </span>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: 160, overflow: "scroll", fontSize: 12 }}>
                        {
                            Object.keys(languages).map((item, index) => {
                                return <li key={'li' + index} sytle={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden"
                                }}>&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" checked={this.props.selectedLanguages.indexOf(item) > -1 ? true : false} onClick={() => { this.props.handleChange(item) }} />&nbsp;&nbsp;&nbsp;&nbsp;{languages[item]}</li>
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                </div>
                {
                    this.props.selectedLanguages.length > 0 ? (
                        <div style={{margin: "10px 0"}}>
                            {
                                this.props.selectedLanguages.map((i, index) => {
                                    return <div className={locatStyle["vselected"]} key={'fatimes' + i}>
                                        {languages[i]}
                                        <FaTimes className={locatStyle["fa-times"]} onClick={() => { this.props.handleChange(i) }} />
                                    </div>
                                })
                            }
                        </div>
                    ) : null
                }
            </div>
        )
    }
};

export default AddLanguageModal;