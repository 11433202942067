import { Spin } from 'antd';
import React, { useState } from 'react';

const SendPasswordResetInstructionPage = () => {
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [login, setLogin] = useState(null);

  const validation = () => {
    return login;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (validation()) {
      return false;
    }
    setLoading(true);
  };

  return (
    <div
      style={{
        borderTop: `6px solid #3f86c7`,
        backgroundColor: 'white',
        borderRadius: '4px',
        overflow: 'hidden',
        boxShadow: '0px 3px 10px #ddd',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8rem',
        marginBottom: '8rem',
      }}
      className="auth_body"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '32px',
          marginTop: '32px',
        }}
      >
        <p
          className='text-capitalize'
          style={{
            fontWeight: `700`,
            fontSize: '24px',
            marginBottom: '0',
            color: 'black',
          }}
        >
          Send Reset Password Instructions
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
          height: '100%',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <>
            <label
              style={{
                marginBottom: '8px',
                fontWeight: '700',
                fontSize: '14px',
                marginTop: '18px',
              }}
            >
              Staff ID / Email
            </label>
            <input
              value={login}
              onChange={(e) => {
                setLogin(e.target.value);
              }}
              className={`form-control ${isSubmit && !login ? 'is-invalid' : ''}`}
              style={{
                height: '50px',
                marginBottom: '5px',
              }}
            />
            <div className="invalid-feedback">{isSubmit && !login ? 'The field is require!' : ''}</div>
          </>
        </div>

        <button
          className="button_auth button_body"
          style={{ marginTop: '32px' }}
          onSubmit={() => handleSubmit()}
          disabled={loading}
        >
          <div
            style={{
              fontWeight: '800',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              color: 'white',
            }}
          >
            {loading && <Spin style={{ marginRight: 10 }} />}
            SEND PASSWORD RESET LINK
          </div>
        </button>
      </form>
    </div>
  );
};

export default SendPasswordResetInstructionPage;
