import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Empty,
  message,
  Modal,
  notification,
  Popconfirm,
  Table,
  Typography,
} from 'antd';
import esiApi from '../../../../api/esiApi';
import { AUTHORIZATION_RIGHTS_URL, AUTHORIZATION_ROLE_CREATE_URL, AUTHORIZATION_ROLE_URL } from '../../../../api/URLs';
import DataTable from '../../../../components/data-table/data-table.component';
import { DeleteColumnOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Search from 'antd/lib/input/Search';
import { useSelector } from 'react-redux';
import ButtontSubmitComponent from '../../../../components/buttonAnt/ButtontSubmitComponent';
import RightsModal from './RightsModal';
import MessageConfirmModal from '../../../../components/messageModal/MessageConfirmModal';
import { Edit } from '@mui/icons-material';
import { getDataRequest } from '../../../../api/serviceMethods';

const RightsAndRolesPage = () => {
  const [allRights, setAllRights] = useState([]);
  const [allRightsList, setAllRightsList] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [originalAllRoles, setOriginalAllRoles] = useState([]);
  const [rightLoading, setRightLoading] = useState(true);
  const [roleLoading, setRoleLoading] = useState(true);
  const [roleTableData, setRoleTableData] = useState([]);
  const [defaultExpandedRowKeys, setDefaultExpandedRowKeys] = useState([]);
  const [noRoleIsChanged, setNoRoleIsChanged] = useState(true);
  const [applyingRoleChange, setApplyingRoleChange] = useState(false);
  const [openRight, setOpenRight] = useState(false)
  const messageModalRef = useRef("");
  const [roleInsert, setRoleInsert] = useState("")

  const user = useSelector((state) => state.authenticatedUser) || { rightList: [] };

  const rightTableSetting = {
    editable: user.rightList.includes('Right_Upsert'),
    removable: user.rightList.includes('Right_Upsert'),
    appendable: user.rightList.includes('Right_Upsert'),
    columns: [
      {
        title: 'Right Name',
        dataIndex: 'name',
        width: '25%',
        nameWhenAppending: ['newName'],
        editRender: false,
        newRecordInputRender: 'input',
      },
      {
        title: 'Description',
        width: '45%',
        dataIndex: 'description',
        nameWhenAppending: ['newDescription'],
        editRender: 'input',
        render: (text) => <Typography.Text type={'secondary'}>{text}</Typography.Text>,
      },
      {
        title: 'Group',
        width: '20%',
        dataIndex: 'group',
        nameWhenAppending: ['newGroup'],
        editRender: 'input',
        render: (text) => <Typography.Text type={'secondary'}>{text}</Typography.Text>,
        filterable: true,
        sorter: (a, b) => (a.group === b.group ? 0 : a.group > b.group ? 1 : -1),
      },
      // {
      //   title: 'Action',
      //   render: (_, record, index) => {
      //     return (
      //       <div className="d-flex">
      //         {!record.group && <Edit style={{ cursor: "pointer" }} onClick={() => {
      //           setOpenRight(record)
      //         }} />}
      //       </div>
      //     )
      //   },
      // },
    ],
    onNewRecordCreate: async (row) => {
      setRightLoading(true);
      try {
        const res = await esiApi.get(AUTHORIZATION_RIGHTS_URL, {
          name: row.newName,
          group: row.newGroup,
          description: row.newDescription,
        });
        const newRightObj = {
          _id: res.data._id,
          name: row.newName,
          group: row.newGroup,
          description: row.newDescription,
          __newRecord__: true,
        };
        setAllRights([...allRights, newRightObj]);
        return Promise.resolve({
          type: 'success',
          text: `New right [${row.newName}] has been added!`,
        });
      } catch (reason) {
        console.error(reason);
        return Promise.reject({
          type: 'error',
          text: 'Failed to add new right',
        });
      } finally {
        setRightLoading(false);
      }
    },
    onRecordEdit: async (recordKey, row) => {
      setRightLoading(true);
      const index = allRights.findIndex((item) => recordKey === item._id);

      const item = allRights[index];
      try {
        await esiApi.put(AUTHORIZATION_RIGHTS_URL + '/' + item.name, { ...row });
        allRights.splice(index, 1, { ...item, ...row });
        setAllRights([...allRights]);
        return Promise.resolve({
          type: 'success',
          text: `Right [${item.name}] has been updated!`,
        });
      } catch (reason) {
        console.error(reason);
        return Promise.reject({
          type: 'error',
          text: `Failed to update Right [${item.name}]`,
        });
      } finally {
        setRightLoading(false);
      }
    },
    onRecordDelete: async (recordKey) =>
      Promise.reject({
        type: 'info',
        text: `Uncheck this Right from all Roles and contact developer to remove this`,
      }),
  };

  function areArraysEqualIgnoreOrder(arr1, arr2) {
    const sortArraysRecursively = (obj) => {
      if (_.isArray(obj)) {
        return _.sortBy(obj.map(sortArraysRecursively));
      } else if (_.isObject(obj)) {
        return _.map(obj, (value, key) => ({ [key]: sortArraysRecursively(value) }));
      }
      return obj;
    };
    return _.isEqual(sortArraysRecursively(arr1), sortArraysRecursively(arr2));
  }

  const confirmDeleteRole = async (role) => {
    let roleName = role.name
    Modal.success({
      title: 'You can delete this role',
      content: <div>Don't forget to confirm the change</div>,
    });
    setAllRoles(allRoles.filter((role) => role.name !== roleName));
  };

  const addNewRole = (values) => {
    let value = roleInsert
    // roleInsert, setRoleInsert
    if (
      !value ||
      allRoles.find((role) => role.name.toLowerCase() === value.toLowerCase())
    ) {
      message.error('invalid or duplicated role name');
      return;
    }
    allRoles.push({ name: value, associatedRights: [] });
    setAllRoles([...allRoles]);
    setRoleInsert('')
  };

  const roleChange = (roleName, rightName, checked) => {
    if (checked) {
      const associatedRights = allRoles.find((role) => role.name === roleName).associatedRights;
      if (!associatedRights.includes(rightName)) {
        allRoles.find((role) => role.name === roleName).associatedRights.push(rightName);
        setAllRoles([...allRoles]);
      }
    } else {
      const toRemovedIndex = allRoles
        .find((role) => role.name === roleName)
        .associatedRights.findIndex((right) => right === rightName);
      if (toRemovedIndex !== -1) {
        allRoles.find((role) => role.name === roleName).associatedRights.splice(toRemovedIndex, 1);
        setAllRoles([...allRoles]);
      }
    }
  };

  const selectOrUnselectAll = (checked, role, groupName) => {
    const thisGroupRights = allRights.filter((ar) => ar.group === groupName).map((r) => r.name);
    if (checked) {
      role.associatedRights = _.union(role.associatedRights, thisGroupRights);
    } else {
      role.associatedRights = role.associatedRights.filter((r) => !thisGroupRights.includes(r));
    }
    setAllRoles([...allRoles]);
  };

  const hecnleSubmitRole = async(payload) => {
    setApplyingRoleChange(true);
    await esiApi
      .post(AUTHORIZATION_ROLE_CREATE_URL, payload)
      .then(() => {
        setOriginalAllRoles(JSON.parse(JSON.stringify(allRoles)));
        messageModalRef.current.showSuccessConfirmsAutoClose(`Role changes have been applied`, () => { }, "", true);
        getAccressRole()
        setApplyingRoleChange(false);
        return true;
      })
      .catch(() => {
        messageModalRef.current.showWarningConfirmsAutoClose(`Unable to apply role changes!`, () => { }, "", true);
        setApplyingRoleChange(false);
        return true;
      })
  }

  const isChangedRole = () => {
    const newRoles = _.differenceBy(allRoles, originalAllRoles, (a) => a.name);
    const deletedRoles = _.differenceBy(originalAllRoles, allRoles, (a) => a.name);
    const changedRoles = [];
    const allRolesStayed = allRoles.filter((role) => originalAllRoles.find((or) => or.name === role.name));
    allRolesStayed.forEach((role) => {
      const correspondingOriginalRole = originalAllRoles.find((or) => or.name === role.name);
      if (!_.isEqual(_.sortBy(correspondingOriginalRole.associatedRights), _.sortBy(role.associatedRights))) {
        changedRoles.push(role);
      }
    });

    const payload = {
      newRoles,
      deletedRoles,
      changedRoles,
    };

    return newRoles.length > 0 || deletedRoles.length > 0 || changedRoles.length > 0
  }
  
  const confirmRoleChange = () => {
    const newRoles = _.differenceBy(allRoles, originalAllRoles, (a) => a.name);
    const deletedRoles = _.differenceBy(originalAllRoles, allRoles, (a) => a.name);
    const changedRoles = [];
    const allRolesStayed = allRoles.filter((role) => originalAllRoles.find((or) => or.name === role.name));
    allRolesStayed.forEach((role) => {
      const correspondingOriginalRole = originalAllRoles.find((or) => or.name === role.name);
      if (!_.isEqual(_.sortBy(correspondingOriginalRole.associatedRights), _.sortBy(role.associatedRights))) {
        changedRoles.push(role);
      }
    });

    const payload = {
      newRoles,
      deletedRoles,
      changedRoles,
    };

    Modal.confirm({
      title: 'Confirm your changes',
      content: (
        <Collapse defaultActiveKey={['1', '2', '3']}>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#3f8600' }}>created:</span>
              </>
            }
            key="1"
          >
            <div>
              {newRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#cf1322' }}>deleted:</span>
              </>
            }
            key="2"
          >
            <div>
              {deletedRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#16c1f6' }}>modified:</span>
              </>
            }
            key="3"
          >
            <div>
              {changedRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
      ),
      okText: 'Confirm Role Change',
      onOk: () => {
        hecnleSubmitRole(payload)
        // add role
      },
    });
  };


  const getAccressRole = async () => {
    try {
      const rightsResponseNew = await esiApi.get(AUTHORIZATION_RIGHTS_URL);
      const rolesResponseNew = await esiApi.get(AUTHORIZATION_ROLE_URL);
      let rightsResponse = rightsResponseNew && rightsResponseNew.data
      let rolesResponse = rolesResponseNew && rolesResponseNew.data
      let rightsResponses= rightsResponse.filter(it => it.group)
      setAllRights(rightsResponses);
      setAllRightsList(rightsResponse.map(it => ({
        ...it,
        label: it.name,
        value: it.name,
      })));
      setAllRoles(rolesResponse);
      setOriginalAllRoles(JSON.parse(JSON.stringify(rolesResponse)));
      setRightLoading(false);
      setRoleLoading(false);
    } catch (e) {
      setRightLoading(false);
      setRoleLoading(false);
      console.error('Unable to fetch rights');
    }
  }
  useEffect(() => {
    getAccressRole()
  }, []);

  useEffect(() => {
    const roleTableData = [
      {
        _id: 'ACTION_ROW',
        key: 'ACTION_ROW',
      },
    ];
    const rowKeys = [];
    allRights && allRights.forEach((right) => {
      allRoles.forEach((role) => {
        const thisRoleContainsThisRight = !!role.associatedRights.includes(right.name);
        const thisRightGroup = right.group;
        const existingGroup = roleTableData.find((d) => d.rightName === thisRightGroup);
        if (existingGroup) {
          const existingRight = existingGroup.children.find((d) => d._id === right._id);
          if (existingRight) {
            existingRight[role.name] = thisRoleContainsThisRight;
          } else {
            existingGroup.children.push({
              _id: right._id,
              key: right._id,
              rightName: right.name,
              [role.name]: thisRoleContainsThisRight,
            });
          }
        } else {
          roleTableData.push({
            _id: 'GROUP_' + thisRightGroup.replaceAll(' ', ''),
            key: 'GROUP_' + thisRightGroup.replaceAll(' ', ''),
            rightName: thisRightGroup,
            children: [
              {
                _id: right._id,
                key: right._id,
                rightName: right.name,
                [role.name]: thisRoleContainsThisRight,
              },
            ],
          });
          rowKeys.push('GROUP_' + thisRightGroup.replaceAll(' ', ''));
        }
      });
    });
    setDefaultExpandedRowKeys(rowKeys);
    setRoleTableData(roleTableData);
  }, [allRights, allRoles]);

  useEffect(() => {
    setNoRoleIsChanged(areArraysEqualIgnoreOrder(allRoles, originalAllRoles));
  }, [allRoles, originalAllRoles]);
  const handleRoleChange = (e) => setRoleInsert(e.target.value);


  const roleTableColumns = [
    {
      title: (
        <>
          {allRoles && allRoles.length ? (
            <Search placeholder="new role name" enterButton="Add Role" 
            onChange={handleRoleChange} value={roleInsert} size="middle" onSearch={addNewRole} />
          ) : (
            <></>
          )}
        </>
      ),
      dataIndex: 'rightName',
      width: 320,
    },
  ];

  allRoles && allRoles.forEach((role) => {
    roleTableColumns.push({
      dataIndex: role.name,
      title: (
        <div
          style={{
            writingMode: 'vertical-lr',
            textOrientation: 'mixed',
            whiteSpace: 'nowrap',
            transform: 'rotateZ(225deg)',
          }}
        >
          {role.name}
        </div>
      ),
      render: (text, row) => {
        if (row.key.includes('GROUP_')) {
          return (
            <Checkbox
              indeterminate={(() => {
                const group = row.rightName;
                const thisGroupRights = allRights.filter((ar) => ar.group === group).map((r) => r.name);
                const intersection = _.intersection(role.associatedRights, thisGroupRights);
                if (intersection.length) {
                  return !_.isEqual(_.sortBy(intersection), _.sortBy(thisGroupRights));
                }
                return false;
              })()}
              checked={(() => {
                const group = row.rightName;
                const thisGroupRights = allRights.filter((ar) => ar.group === group).map((r) => r.name);
                const intersection = _.intersection(role.associatedRights, thisGroupRights);
                if (intersection.length) {
                  return _.isEqual(_.sortBy(intersection), _.sortBy(thisGroupRights));
                }
                return false;
              })()}
              onChange={(e) => selectOrUnselectAll(e.target.checked, role, row.rightName)}
            />
          );
        } else if (row.key === 'ACTION_ROW') {
          return (
            <Popconfirm
              title={`Delete role [${role.name}]?`}
              description={
                <>
                  We will check if there are staffs assigned to this role. <br />
                  You will only be able to delete when no one is assigned.
                </>
              }
              onConfirm={() => confirmDeleteRole(role)}
              okText="Check & Delete"
              cancelText="No"
            >
              <Button danger type="text" shape="circle" icon={<DeleteColumnOutlined />} style={{ marginLeft: -8 }} />
            </Popconfirm>
          );
        } else {
          return (
            <Checkbox
              checked={row[role.name]}
              onChange={() => roleChange(role.name, row.rightName, !row[role.name])}
            />
          );
        }
      },
    });
  });
  useEffect(() => {
  }, [allRoles && allRoles.length, noRoleIsChanged]);

  return (
    <div className="container box-shadow h-100"
      style={{
        background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        padding: "32px",
        overflow: "auto"
      }}>

      <div className="w-100">
        <h2 className="text-start">System Setting Management</h2>
      </div>

      <div className="w-100">
        <Card
          title="Right Management"
          // extra={
          //   <ButtontSubmitComponent size={"middle"} handle={() => { setOpenRight(true) }} title={`Create Right`} />
          // }
          style={{ marginBottom: 8 }}>
          <DataTable data={allRightsList || []}
            settings={rightTableSetting} loading={rightLoading}
            isHidePagination={true}
          />
        </Card>
        <Card
          title="Role Configuration"
          extra={
            <ButtontSubmitComponent size={"middle"} disabled={!isChangedRole()} handle={confirmRoleChange} title={`Confirm Changes`} />
          }
        >
          <Table
            dataSource={roleTableData}
            columns={roleTableColumns}
            pagination={false}
            scroll={{ y: 480 }}
            loading={roleLoading || applyingRoleChange}
            expandable={{
              defaultExpandedRowKeys: defaultExpandedRowKeys,
            }}
            locale={{ emptyText: 'No Role' }}
          />
        </Card>
        {openRight ? <RightsModal
          open={openRight} setOpen={setOpenRight}
          getAllDataRequest={getAccressRole}
          allRightsList={allRightsList ? allRightsList.filter(it => !it.group) : []}
        /> : ""}
      </div>

      <MessageConfirmModal textCentered ref={messageModalRef} />
    </div>
  );
};

export default RightsAndRolesPage;
