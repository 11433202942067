import React, { useEffect, useRef, useState } from 'react';
import { AUTHORIZATION_ROLE_URL, STAFF_CREATE_URL, STAFF_DETAIL_URL } from '../../api/URLs';
import './staff-create.css';
import '../member-pages/style.css';
import { Button, Col, Input, message, Row, Select, Spin, } from 'antd';
import InputComponent from '../../components/input/InputComponent';
import DateComponent from '../../components/date/DateComponent';
import SelectComponent from '../../components/select/SelectComponent';
import ButtontSubmitComponent from '../../components/buttonAnt/ButtontSubmitComponent';
import InputMask from 'react-input-mask';
import { getDataRequest, postDataRequest, putDataRequest } from '../../api/serviceMethods';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import { useLocation, useNavigate } from 'react-router-dom';


function StaffCreatePage() {
  const [allRoles, setAllRoles] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const fetchAllRoles = () => {
    (async () => {
      const resRole = await getDataRequest(AUTHORIZATION_ROLE_URL);
      setAllRoles(resRole);
    })();
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);

  const { TextArea } = Input;
  const [isSubmit, setIsSubmit] = useState(false)
  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const messageModalRef = useRef("");
  const navigate = useNavigate();
  const genderData = [
    {
      label: 'Male',
      value: 'male',
    },
    {
      label: 'Female',
      value: 'female',
    },
  ]

  const [dataObject, setDataObject] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    title: '',
    gender: '',
    languages: [],
    phone: {
      countryCode: '852',
      number: ''
    },
    role: '',
    dob: '',
    description: [''],
    introduction: ''
  });

  const checkError = () => {
    if (
      !dataObject.email || 
      !dataObject.firstName || !dataObject.lastName ||
      !dataObject.phone.countryCode || !dataObject.phone.number ||
      !dataObject.title || !dataObject.gender || !dataObject.languages ||
      !dataObject.role ||
      !dataObject.dob || (!id && (!dataObject.password || !dataObject.confirmPassword))
    ) {
      return true
    }
    return false
  }

  const phoneData = [
    {
      label: "+852",
      value: "852"
    },
    {
      label: "+86",
      value: "86"
    }
  ]

  const addNewValue = async (data) => {
    setDataObject({
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      title: data.title,
      gender: data.gender,
      languages: data.languages,
      phone: {
        countryCode: `${data.phone.countryCode}`,
        number: data.phone.number,
      },
      role: data && data.role && data.role._id,
      dob: data.dob,
      description: data.description,
      introduction: data.introduction
    })
  }

  // get member Id
  const getMemberById = async () => {
    try {
      setLoading(true);
      await getDataRequest(`${STAFF_DETAIL_URL}/${id && id}`)
        .then((res) => {
          if (res) {
            addNewValue(res)
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error('Server Error!');
    }
  }
  useEffect(() => {
    if (id && id) { getMemberById() }
  }, []);

  // update Member
  const hadleUpdateStaff = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    try {
      const memberPayload = {
        email: dataObject.email,
        password: dataObject.password,
        firstName: dataObject.firstName,
        lastName: dataObject.lastName,
        title: dataObject.title,
        gender: dataObject.gender,
        languages: dataObject.languages,
        phone: {
          countryCode: `${dataObject && dataObject.phone && dataObject.phone.countryCode}`,
          number: isChange ? dataObject?.phone?.number.replace(/[^0-9]/g, '') || '' : dataObject?.phone?.number || '',
        },
        role: dataObject.role,
        dob: dataObject.dob,
        description: dataObject.description,
        introduction: dataObject.introduction
      }
      if (checkError()) {
        return false;
      }
      setLoading(true);
      await putDataRequest(`/staffs/update/${id && id} `, memberPayload)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            setTimeout(() => {
              navigate("/staff/search")
            }, 2000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        });
    } catch (error) {
      console.log("error", error)
      setLoading(false);
      message.error('Server Error!');
    }
  }

  // Save member
  const handleSubmit = async () => {
    setIsSubmit(true)
    if (checkError()) {
      return false
    }
    setLoading(true);
    const memberPayload = {
      email: dataObject.email,
      password: dataObject.password,
      firstName: dataObject.firstName,
      lastName: dataObject.lastName,
      title: dataObject.title,
      gender: dataObject.gender,
      languages: dataObject.languages,
      countryCode: dataObject && dataObject.phone && dataObject.phone.countryCode,
      phoneNumber: dataObject?.phone?.number.replace(/[^0-9]/g, '') || '',
      role: dataObject.role,
      dob: dataObject.dob,
      description: dataObject.description,
      introduction: dataObject.introduction
    }
    try {
      const res = await postDataRequest(STAFF_CREATE_URL, memberPayload);
      if (res?.status === 200) {
        messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || 'Saved Successfully', () => { }, "", true);
        setTimeout(() => {
          navigate("/staff/search")
        }, 2000);
      } else {
        messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || 'An error occurred.', () => { }, "", true);
      }
    } catch (err) {
      messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  }

  const addDescription = () => {
    const des = { ...dataObject };
    des.description = [...des.description, ''];
    setDataObject(des);
  };

  const removeDescription = () => {
    const des = { ...dataObject };
    let description = [...des.description];
    description.pop();
    des.description = description;
    setDataObject(des);
  };

  return (
    <div className="container box-shadow h-100 mymain"
      style={{
        // background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        padding: "32px",
        // overflow: "auto"
      }}
    >
      <Spin spinning={loading}>
        <div className="w-100 bluediv">
          <div className="w-100 h-100 p-2 pt-3 ps-5 pe-5 d-flex flex-column justify-content-between" style={{ background: "#fff", borderRadius: "4px" }}>
            <div className='w-100'>
              <Row className='mt-2 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Email: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <InputComponent type="email" status={isSubmit && !dataObject.email && `error`} value={dataObject.email} setValue={(value) => setDataObject({ ...dataObject, email: value })} placeholder={`Enter email`} />
                </Col>
              </Row>
              {
                id && id ? "" :
                  <>
                    <Row className='mt-3 d-flex align-items-center'>
                      <Col span={6}>
                        <div>
                          <label htmlFor=''><span className='text-danger'>*</span>Password: </label>
                        </div>
                      </Col>
                      <Col span={12}>
                        <InputComponent status={isSubmit && !dataObject.password && `error`} value={dataObject.password} setValue={(value) => setDataObject({ ...dataObject, password: value })} placeholder={`Enter password`} type="password" />
                      </Col>
                    </Row>
                    <Row className='mt-3 d-flex align-items-center'>
                      <Col span={6}>
                        <div>
                          <label htmlFor=''><span className='text-danger'>*</span>Confirm Password: </label>
                        </div>
                      </Col>
                      <Col span={12}>
                        <InputComponent
                          style={{
                            border: dataObject.confirmPassword && dataObject.password !== dataObject.confirmPassword ? '1px solid red' : '',
                          }}
                          status={isSubmit && !dataObject.confirmPassword && `error`}
                          value={dataObject.confirmPassword}
                          setValue={(value) => setDataObject({ ...dataObject, confirmPassword: value })}
                          placeholder={`Enter confirm password`} type="password"
                          textError={dataObject.confirmPassword && dataObject.password !== dataObject.confirmPassword && "Passwords do not match."} />
                      </Col>
                    </Row>
                  </>
              }
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>First Name: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <InputComponent status={isSubmit && !dataObject.firstName && `error`} value={dataObject.firstName} setValue={(value) => setDataObject({ ...dataObject, firstName: value })} placeholder={`Enter first name`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Last Name: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <InputComponent status={isSubmit && !dataObject.lastName && `error`} value={dataObject.lastName} setValue={(value) => setDataObject({ ...dataObject, lastName: value })} placeholder={`Enter last name`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Title: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <InputComponent status={isSubmit && !dataObject.title && `error`} value={dataObject.title} setValue={(value) => setDataObject({ ...dataObject, title: value })} placeholder={`Enter title`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Gender: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <SelectComponent status={isSubmit && !dataObject.gender && `error`} options={genderData} value={dataObject.gender} setValue={(value) => setDataObject({ ...dataObject, gender: value })} placeholder={`Select gender`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Languages: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <InputComponent
                    status={isSubmit && (!dataObject.languages || dataObject.languages.length === 0) && `error`}
                    value={Array.isArray(dataObject.languages) ? dataObject.languages : (dataObject.languages || "").split(/[, ]+/)}
                    setValue={(value) => setDataObject({ ...dataObject, languages: Array.isArray(value) ? value : value.split(/[, ]+/) })}
                    placeholder={`Enter languages, separated by comma or space`}
                  />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Phone</label>
                  </div>
                </Col>
                <Col span={12}>
                  <Select
                    className="selectCus"
                    value={dataObject.phone.countryCode}
                    size="large"
                    style={{
                      width: 100,
                    }}
                    onChange={(value) => setDataObject({
                      ...dataObject,
                      phone: {
                        ...dataObject.phone,
                        countryCode: value,
                      },
                    })}
                    options={phoneData}
                  />
                  <InputMask
                    key={dataObject.phone.countryCode}
                    value={dataObject.phone.number}
                    onChange={(event) => {
                      setDataObject({
                        ...dataObject,
                        phone: {
                          ...dataObject.phone,
                          number: event.target.value,
                        }
                      });
                      setIsChange(true);
                    }}
                    className={`inputCustomPho ${isSubmit && !dataObject.phone.number ? 'input-error-cus' : ''}`}
                    mask={dataObject.phone.countryCode === "852" ? "9999-9999" : "999-9999-9999"}
                  />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Role:</label>
                  </div>
                </Col>
                <Col span={12}>
                  <SelectComponent status={isSubmit && !dataObject.role && `error`} options={(allRoles?.data || []).map(role => ({ label: role.name, value: role._id }))} value={dataObject.role} setValue={(value) => setDataObject({ ...dataObject, role: value })} placeholder={`Select role`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Date of Birth: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <DateComponent width="100%" status={isSubmit && !dataObject.dob && `error`} value={dataObject.dob ? dataObject.dob : null} setValue={(value) => setDataObject({ ...dataObject, dob: value })} />
                </Col>
              </Row>
              {dataObject.description.map((value, index) => (
                <Row key={index} className="mt-3 d-flex">
                  <Col span={6}>
                    <div>
                      <label htmlFor={`description_${index}`}>
                        Description {index + 1}:
                      </label>
                    </div>
                  </Col>
                  <Col span={12} className="mb-3">
                    <TextArea
                      id={`description_${index}`}
                      value={value}
                      // status={isSubmit && !value && `error`}
                      onChange={(e) => {
                        const updatedDescriptions = [...dataObject.description];
                        updatedDescriptions[index] = e.target.value;
                        setDataObject({ ...dataObject, description: updatedDescriptions });
                      }}
                      placeholder="Enter description"
                      autoSize={{
                        minRows: 2,
                        maxRows: 3,
                      }}
                    />
                  </Col>
                </Row>
              ))}
              <div className="d-grid gap-2 d-flex" style={{ marginLeft: '550px', marginTop: dataObject.description.length > 0 ? "" : "15px" }}>
                <Button
                  type=""
                  size="middle"
                  color="default"
                  variant="filled"
                  style={{ minWidth: "8rem", paddingLeft: "5px", paddingRight: "5px" }}
                  className={`me-2`}
                  onClick={addDescription}
                >
                  Add Description
                </Button>
                <Button
                  type=""
                  size="middle"
                  variant="solid"
                  color="danger"
                  style={{ minWidth: "8rem", paddingLeft: "5px", paddingRight: "5px" }}
                  className={`me-5`}
                  onClick={removeDescription}
                  disabled={!dataObject.description || dataObject.description.length === 0}
                >
                  Remove Last Description
                </Button>
              </div>
              <Row className='mt-3 d-flex'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''>Introduction: </label>
                  </div>
                </Col>
                <Col span={12} className='mb-3'>
                  <TextArea
                    value={dataObject.introduction}
                    onChange={(e) => {
                      setDataObject({ ...dataObject, introduction: e.target.value })
                    }}
                    placeholder="Enter introduction"
                    autoSize={{
                      minRows: 5,
                      maxRows: 7,
                    }}
                  />

                </Col>
              </Row>
            </div>
            <Row className='mt-2'>
              <Col span={24}>
                <div className='text-center pb-3'>
                  <Button
                    type=""
                    size="large"
                    style={{ minWidth: "8rem", paddingLeft: "5px", paddingRight: "5px" }}
                    className={`me-5`}
                    onClick={() => {
                      navigate(`/staff/search`)
                    }}
                  >Back</Button>
                  <ButtontSubmitComponent handle={id && id ? hadleUpdateStaff : handleSubmit} title={`Submit`} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </div>
  );
}

export default StaffCreatePage;
