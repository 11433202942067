import { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import esiApi from '../../api/esiApi';
import { UPLOAD_IMAGE_URL } from '../../api/URLs';
import BoxLoader from '../../components/loading/BoxLoader';
import { fileToBase64 } from '../../share/StringHelper';
import './style.css';
import { Button, Input, Select } from 'antd';

function PaymentCreatePage() {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false)

  const [dataObject, setDataObject] = useState({
    email: '',
    firstName: '',
    lastName: '',
    title: '',
    role: '',
    position: '',
    dob: null,
    password: '',
    introduction: '',
    description: [''],
    countryCode: 1,
    phoneNumber: '',
    gender: '',
    languages: [],
    affiliations: [''],
    accreditations: [''],
    locations: [''],
    services: [''],
    coverImage: '',
    dea: '',
    me: '',
  });

  const [loading, setloading] = useState(false);


  return (
    <div className="container box-shadow h-100 mymain"
      style={{
        background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        padding: "32px",
        overflow: "auto"
      }}
    >

      <div className="w-100">
        <h2 className="text-start">Create Payment Management</h2>
      </div>

      <div className="w-100 bluediv">

        <div className="w-100 h-100 p-2" style={{background: "#fff" }}>
          <table className='w-100'>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Numbering: </td>
              <td className='ps-2'>2024110133431</td>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Create date: </td>
              <td className='ps-2'>2024-11-01 10:13:14</td>
            </tr>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Status: </td>
              <td className='ps-2'>
                {isEdit ? <Select
                  value="Wait for payment"
                  placeholder={"Status"}
                  style={{
                    width: 175,
                  }}
                  // onChange={handleChange}
                  options={[
                    {
                      value: 'Wait for payment',
                      label: 'Wait for payment',
                    },
                    {
                      value: 'Payment successful',
                      label: 'Payment successful',
                    },
                    {
                      value: 'Payment failed',
                      label: 'Payment failed',
                    },
                    {
                      value: 'Payment cancelled',
                      label: 'Payment cancelled',
                    },
                  ]}
                /> : `Wait for payment`}
                {
                  isEdit ? <>
                  <Button className='ms-1' onClick={() => setIsEdit(false)} style={{width: "4rem"}} block>Save</Button>
                  <Button className='ms-1' onClick={() => setIsEdit(false)} style={{width: "4rem"}} block>Cancel</Button>
                  </> : <Button className='ms-1' onClick={() => setIsEdit(true)} style={{width: "4rem"}} block>Edit</Button>
                }
                
              </td>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Payment date: </td>
              <td className='ps-2'>2024-11-01 10:13:14</td>
            </tr>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Types of:	</td>
              <td className='ps-2'>S</td>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>The way: </td>
              <td className='ps-2'>Online payments</td>
            </tr>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Payment method:	</td>
              <td className='ps-2'>Order online - FPS Payment	</td>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Operator: </td>
              <td className='ps-2'>Member</td>
            </tr>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Beneficiary Bank:	</td>
              <td className='ps-2'>-</td>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Collection account: </td>
              <td className='ps-2'>-</td>
            </tr>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Payer: </td>
              <td className='ps-2'>-</td>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Payment amount: </td>
              <td className='ps-2'>HKD280.00</td>
            </tr>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Member: </td>
              <td className='ps-2'>852-61819717	</td>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Order: </td>
              <td className='ps-2'>2024110133431</td>
            </tr>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}>Remarks: </td>
              <td className='ps-2'>The price not yet pay</td>
            </tr>
            <tr style={{ borderBottom: "1px solid #0000003b" }}>
              <td className='pt-2 pb-2 pe-2' style={{ background: "#f9f9f9", textAlign: "end" }}></td>
              <td className='pt-2 pb-2 pe-2 ps-2'><Button onClick={() => navigate(`/payment`)} style={{width: "8rem"}} block>Back</Button></td>
            </tr>
          </table>
        </div>

      </div>
    </div>
  );
}

export default PaymentCreatePage;
