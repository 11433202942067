import React, { useEffect, useState } from "react";
import DataTable from "../../components/data-table/data-table.component";
import { Button, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import TableFetchData from "../../components/data-table/TableFetchData";
import { getDataRequest } from "../../api/serviceMethods";

const PaymentPage = () => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([
    {
      numbering: "2024110133431",
      typesOf: "S",
      theway: "Online payments",
      paymentMethod: "Online payments",
      paymentAmount: "Order online - FPS Payment",
      member: "280",//HKD280.00
      orderNum: "2024110133431",
      status: "Wait for payment",
      paymentDate: "-",//-
      createDate: "2024-11-01",
    },
    {
      numbering: "2024110133431",
      typesOf: "S",
      theway: "Online payments",
      paymentMethod: "Online payments",
      paymentAmount: "Order online - FPS Payment",
      member: "280",//HKD280.00
      orderNum: "2024110133431",
      status: "Payment successful",
      paymentDate: "2024-11-10",//-
      createDate: "2024-11-01",
    },
    {
      numbering: "2024110133431",
      typesOf: "M",
      theway: "Payment failed	",
      paymentMethod: "Online payments",
      paymentAmount: "Order online - FPS Payment",
      member: "280",//HKD280.00
      orderNum: "2024110133431",
      status: "Wait for payment",
      paymentDate: "2024-11-10",//-
      createDate: "2024-11-01",
    },
    {
      numbering: "2024110133431",
      typesOf: "M",
      theway: "Payment cancelled	",
      paymentMethod: "Online payments",
      paymentAmount: "Order online - FPS Payment",
      member: "280",//HKD280.00
      orderNum: "2024110133431",
      status: "Wait for payment",
      paymentDate: "2024-11-10",//-
      createDate: "2024-11-01",
    },
  ]);
  const navigate = useNavigate();
  const [keyword, setkeyword] = React.useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 5000);
  }, [])

  const fetchData = (value) => {
    setLoading(true)
    getDataRequest(`/members`, {
      keyword: value ? value.trim() : '',
      pageNo: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,

    })
    .then(res => {
      setLoading(false)
      if(res.data) {
        
      }
      setData(res.data ? res.data.map(it => ({...it,
        numbering: it.memberId,
        typesOf: "S",
        theway: "Online payments",
        paymentMethod: "Online payments",
        paymentAmount: "Order online - FPS Payment",
        member: "280",//HKD280.00
        orderNum: "2024110133431",
        status: "Wait for payment",
        paymentDate: "-",//-
        createDate: "2024-11-01",
      })) : [])
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.total,
        },
      });
    })
    .catch((err) => {
      setLoading(false)

    })
  }
  const tableSettings = [
    {
      title: <span style={{ textWrap: "nowrap" }}>Numbering</span>,
      dataIndex: 'numbering',
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Types of</span>,
      dataIndex: 'typesOf',
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>The way</span>,
      dataIndex: 'theway',
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Payment method</span>,
      dataIndex: 'paymentMethod',//size
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Payment amount</span>,
      dataIndex: 'paymentAmount',//size
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Member</span>,
      dataIndex: 'member',//size
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Order</span>,
      dataIndex: 'orderNum',//size
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Status</span>,
      dataIndex: 'status',//size
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Payment date</span>,
      dataIndex: 'paymentDate',//size
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Create date</span>,
      dataIndex: 'createDate',//size
    },
    {
      title: <span style={{ textWrap: "nowrap" }}>Action</span>,
      render: (_, record) => {
        return <>
          <span className="cursor-pointer"
            //   loading={buttonLoading[record.orderID] || false}
            onClick={() => {
              navigate(`/payment/view?${record.numbering}`)
            }}
            target="_blank"
          >
            View
          </span>
        </>
      },
    },
  ]

  useEffect(() => {
    fetchData(keyword)
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);


  return (
    <div className="container box-shadow h-100"
      style={{
        // background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        // padding: "32px",
        // overflow: "auto"
      }}>

      <div className="w-100 h-100 overflow-auto">
        <div className="w-100">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <div className="d-flex align-items-center">
              <Input.Search
                placeholder="Search Order, Payment Number"
                size="large"
                disabled={loading}
                loading={loading}
                className="me-2"
                allowClear
                style={{ width: '20rem' }}
                value={keyword}
              onChange={(e) => setkeyword(e.target.value)}
              onSearch={async (value) => {
                setkeyword(value);
                setTableParams({
                  ...tableParams,
                  pagination: {
                    ...tableParams.pagination,
                    current: 1,
                    total: 0,
                  },
                });
                if (value === "") {
                  fetchData("");
                } else {
                  fetchData(value);
                }
              }}
              />
              {/* <Select
                                size={'large'}
                                // value={selectedLocation}
                                style={{ width: 350 }}
                                // onChange={(value) => setSelectedLocation(value)}
                                // options={locationOptions}
                                disabled={loading}
                                loading={loading}
                                placeholder={loading ? 'Fetching Locations' : 'Select Type'}
                            /> */}
            </div>
            <div>
              {/* <Button type="primary" size={'large'}
                                onClick={() => navigate("/member/view")}
                            >
                                + New Appointment
                            </Button> */}
            </div>
          </div>
        </div>

        <div className="w-100">
          <TableFetchData
          tableParams={tableParams} setTableParams={setTableParams}
          data={data}
          columns={tableSettings}
          loading={loading} setLoading={setLoading}
          />
          {/* <DataTable
                        loading={loading}
                        data={data}
                        settings={tableSettings}
                    /> */}
        </div>

      </div>
    </div>
  )
}

export default PaymentPage