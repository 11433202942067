import React, { useRef } from "react";
import MessageConfirmModal from "../../components/messageModal/MessageConfirmModal";
import DashboardComponent from "../../components/dashboard/dashboardMember";
import TableUserManagerment from "../../components/dashboard/tabUserManagement";

const MemberPage = () => {
    const messageModalRef = useRef("");
    return (
        <div className="box-shadow h-100 w-100">
            <div className="w-100 h-100 overflow-auto">
                <div className="w-100">
                    <DashboardComponent />
                    <TableUserManagerment messageModalRef={messageModalRef} />
                </div>
                <MessageConfirmModal textCentered ref={messageModalRef} />
            </div>
        </div>
    )
}

export default MemberPage