import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Spin } from 'antd';
import { Translation } from 'react-i18next'
import { validateEmail } from '../../utils/Validate';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const RegisterPage = () => {
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [phone, setPhone] = useState('');
  const [loginData, setLoginData] = useState({
    login: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleHide = () => {
    setIsShow(!isShow);
  };

  const validation = () => {
    if (!loginData.login) {
      return true;
    }
    return !loginData.password;
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(loginData.password === e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (validation()) {
      return false;
    }
    setLoading(false)
  }


  return (
    <Translation>
      {t => (
        <div
          style={{
            borderTop: `6px solid #3f86c7`,
            backgroundColor: 'white',
            borderRadius: '4px',
            overflow: 'hidden',
            boxShadow: '0px 3px 10px #ddd',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '8rem',
            marginBottom: '8rem',
          }}
          className="auth_body"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '32px',
              marginTop: '32px',
            }}
          >
            <p
              className='text-capitalize'
              style={{
                fontWeight: `700`,
                fontSize: '24px',
                marginBottom: '0',
                color: 'black',
              }}
            >
              {t("Register")}
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            style={{
              width: '100%',
              height: '100%',
              padding: '32px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              <div>
                <label
                  style={{
                    marginBottom: '8px',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginTop: '18px',
                  }}
                >
                  {t("First Name")}
                </label>
                <input
                  value={loginData.firstName}
                  onChange={(e) => {
                    setLoginData({
                      ...loginData,
                      firstName: e.target.value,
                    });
                  }}
                  className={`form-control ${isSubmit && !loginData.firstName ? 'is-invalid' : ''}`}
                  style={{
                    height: '50px',
                    marginBottom: '5px',
                  }}
                />
                <div className="invalid-feedback">{isSubmit && !loginData.firstName ? t('The field is require!') : ''}</div>
              </div>
              <div>
                <label
                  style={{
                    marginBottom: '8px',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginTop: '18px',
                  }}
                >
                  {t("Last Name")}
                </label>
                <input
                  value={loginData.lastName}
                  onChange={(e) => {
                    setLoginData({
                      ...loginData,
                      lastName: e.target.value,
                    });
                  }}
                  className={`form-control ${isSubmit && !loginData.lastName ? 'is-invalid' : ''}`}
                  style={{
                    height: '50px',
                    marginBottom: '5px',
                  }}
                />
                <div className="invalid-feedback">{isSubmit && !loginData.lastName ? t('The field is require!') : ''}</div>
              </div>
              <div>
                <label
                  style={{
                    marginBottom: '8px',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginTop: '18px',
                  }}
                >
                  {t("Email")}
                </label>
                <input
                  type='email'
                  value={loginData.email}
                  onChange={(e) => {
                    setLoginData({
                      ...loginData,
                      email: validateEmail(e.target.value),
                    });
                  }}
                  className={`form-control ${isSubmit && !loginData.email ? 'is-invalid' : ''}`}
                  style={{
                    height: '50px',
                    marginBottom: '5px',
                  }}
                />
                <div className="invalid-feedback">{isSubmit && !loginData.email ? t('The field is require!') : ''}</div>
              </div>
              <div>
                <label
                  style={{
                    marginBottom: '8px',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginTop: '18px',
                  }}
                >
                  {t("Phone")}
                </label>
                {/* <input
                  value={loginData.phone}
                  onChange={(e) => {
                    setLoginData({
                      ...loginData,
                      phone: e.target.value,
                    });
                  }}
                  className={`form-control ${isSubmit && !loginData.phone ? 'is-invalid' : ''}`}
                  style={{
                    height: '50px',
                    marginBottom: '5px',
                  }}
                /> */}
                <PhoneInput
                  country={'hk'} // Default to Hong Kong
                  onlyCountries={['hk', 'cn']} // Restrict to Hong Kong and China
                  value={phone}
                  // onChange={(e) => {
                  //   setLoginData({
                  //     ...loginData,
                  //     phone: e,
                  //   });
                  // }}
                  onChange={(value) => setPhone(value)}
                  countryCodeEditable={false} // Make the country code non-editable
                />
                <div className="invalid-feedback">{isSubmit && !loginData.phone ? t('The field is require!') : ''}</div>
              </div>
              <div>
                <label
                  style={{
                    marginBottom: '8px',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginTop: '18px',
                  }}
                >
                  Password
                </label>
                <div style={{ position: 'relative' }}>
                  <input
                    type={isShow ? 'text' : 'password'}
                    className={`form-control ${isSubmit && !loginData.password ? 'is-invalid' : ''}`}
                    style={{
                      height: '50px',
                    }}
                    value={loginData.password}
                    onChange={(e) => {
                      setLoginData({
                        ...loginData,
                        password: e.target.value,
                      });
                    }}
                  />
                  <div className="invalid-feedback">{isSubmit && !loginData.password ? 'The field is require!' : ''}</div>
                  {isSubmit && !loginData.password ? (
                    ''
                  ) : (
                    <div
                      onClick={() => handleHide()}
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        margin: '15px 15px',
                        cursor: 'pointer',
                      }}
                    >
                      {isShow ? <AiFillEye size={24} /> : <AiFillEyeInvisible size={24} />}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label
                  style={{
                    marginBottom: '8px',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginTop: '18px',
                  }}
                >
                  Confirm Password
                </label>
                <div style={{ position: 'relative' }}>
                  <input
                    type={isShow ? 'text' : 'password'}
                    className={`form-control ${(isSubmit && !loginData.password) || !passwordsMatch ? 'is-invalid' : ''}`}
                    style={{
                      height: '50px',
                    }}
                    value={confirmPassword}
                    onChange={(e) => {
                      handleConfirmPasswordChange(e);
                    }}
                  />
                  <div className="invalid-feedback">
                    {!passwordsMatch && 'Password not match'}
                    {isSubmit && !loginData.password ? 'The field is require!' : ''}
                  </div>
                  {isSubmit && !loginData.password ? (
                    ''
                  ) : (
                    <div
                      onClick={() => handleHide()}
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        margin: '15px 15px',
                        cursor: 'pointer',
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>

            <button
              className="button_auth button_body"
              style={{ marginTop: '32px' }}
              onSubmit={() => handleSubmit()}
              disabled={loading}
            >
              <div
                style={{
                  fontWeight: '800',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'white',
                }}
              >
                {loading && <Spin style={{ marginRight: 10 }} />}
                {t("Register")}
              </div>
            </button>
            <div
              className="link_auth"
              style={{ marginTop: '10px', cursor: "pointer" }}
              onClick={() => navigate('/')}
              disabled={loading}
            >
              {t("login")}
            </div>
          </form>
        </div>)}
    </Translation>
  );
};

export default RegisterPage;
