import React, { useEffect, useRef, useState } from "react";
import { Button, Image, Input, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { MAGAZINES_URL } from "../../api/URLs";
import { getDataRequest, putDataRequest } from "../../api/serviceMethods";
import moment from "moment";
import MessageConfirmModal from "../../components/messageModal/MessageConfirmModal";
import TableFetchData from "../../components/data-table/TableFetchData";

const MagazinePage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const messageModalRef = useRef("");
  const [keyword, setkeyword] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  // for list all magazines
  const initLoadMagazine = async (value) => {
    setLoading(true);
    await getDataRequest(`${MAGAZINES_URL}`, {
      keyword: value ? value.trim() : '',
      pageNo: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
    }).then((res) => {
      if (res.status === 200) {
        setData(res?.data);
      }
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.total,
        },
      });
      setLoading(false);
    }).catch((err) => {
      setLoading(false)
      console.log("Error", err)
    });
  };

  // update status magazine
  const handleUpdateStatus = async (item) => {
    setLoading(true)
    await putDataRequest(`${MAGAZINES_URL}/${item.magazineId} /publish`, {
      publish: !item.status,
    }).then((res) => {
      setLoading(false)
      if (res.status === 200) {
        messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
        initLoadMagazine();
      }
    })
      .catch((err) => {
        setLoading(false)
        console.log("Error", err)
      });
  };

  useEffect(() => {
    initLoadMagazine(keyword)
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);

  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: "small",
    pagination: {
      showLessItems: true,
      pageSize: 10,
    },
    columns: [
      {
        title: 'Magazine Id',
        dataIndex: 'magazineId',
      },
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Cover Image',
        render: (_, record) => {
          return (
            <Image
              src={`${record?.picUrl}`}
              width={50}
              height={50}
              alt="Cover Image"
            />
          )
        }
      },
      {
        title: 'Category',
        render: (_, record) => {
          return (
            <div className="d-flex text-capitalize">
              {record.category}
            </div>
          )
        }
      },
      {
        title: 'Author',
        render: (_, record) => {
          return (
            <div className="text-capitalize">{record.author}</div>
          )
        }
      },
      {
        title: 'Date',
        render: (_, record) => {
          return (
            <label className="">{moment(record.uploadedTS).format("YYYY-MM-DD")}</label>
          )
        },
      },
      {
        title: 'Tags',
        render: (_, record) => {
          return (
            <div className="d-flex text-capitalize">
              {record.tags.map((tag, index) => (
                <span key={index}>
                  {tag}
                  {index < record.tags.length - 1 ? ', ' : ''}
                </span>
              ))}
            </div>
          );
        }
      },
      {
        title: 'Preview',
        dataIndex: 'previewPage',
      },
      {
        title: 'Publisher',
        render: (_, record) => {
          return (
            <div className="text-capitalize">{record.publisher}</div>
          )
        }
      },
      {
        title: 'Status',
        render: (_, record) => {
          return (
            <>
              {
                record.status === true ?
                  <label className="">Active</label> :
                  <label className="">Inactive</label>
              }
            </>
          )
        },
      },
      {
        title: 'Action',
        render: (_, record) => {
          return (
            <div className="d-flex">
              <Switch
                style={{ marginRight: "20px" }}
                size={'medium'}
                checked={record.status}
                onChange={() => {
                  handleUpdateStatus(record);
                }}
              />
              <Edit style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClickEdit(record)
                  navigate(`/magazine/create?id=${record.magazineId}`)
                }}
              />
            </div>
          )
        },
      },
    ],
  };

  // update data magazine
  const handleClickEdit = () => {
    navigate("/magazine/create")
  }

  return (
    <div className="container box-shadow w-100 h-100"
      style={{
        // background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        // padding: "32px",
        // overflow: "auto"
      }}>
      <div className="w-100 h-100 overflow-auto">
        <div className="w-100">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <div className="d-flex align-items-center col-6">
              <Input.Search
                placeholder="Search By Magazine Id, Title, Category, Author"
                size="large"
                disabled={loading}
                loading={loading}
                className="me-2"
                allowClear
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
                onSearch={async (value) => {
                  setkeyword(value);
                  setTableParams({
                    ...tableParams,
                    pagination: {
                      ...tableParams.pagination,
                      current: 1,
                      total: 0,
                    },
                  });
                  if (value === "") {
                    initLoadMagazine("");
                  } else {
                    initLoadMagazine(value);
                  }
                }}
              />
            </div>
            <div>
              <Button type="primary" size={'large'}
                onClick={() => navigate("/magazine/create")}
              >
                Create Magazine
              </Button>
            </div>
          </div>
        </div>
        <div className="w-100" 
        // style={{ overflowY: "auto" }}
        >
          <TableFetchData
            tableParams={tableParams} setTableParams={setTableParams}
            data={data}
            columns={tableSettings.columns}
            loading={loading} setLoading={setLoading}
          />
        </div>
      </div>
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </div>
  )
}

export default MagazinePage