// Staging
export const ADMIN_ENDPOINT = "https://esiproapi.staging.nowbst.com/admin";

// QA
// export const ADMIN_ENDPOINT = "https://esiproapi.staging.nowbst.com/admin";

// Prod
// export const ADMIN_ENDPOINT = "https://esiproapi.staging.nowbst.com/admin";

export default {
  ADMIN_ENDPOINT,
};
