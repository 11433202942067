import { Button, Input, message, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StaffDetailsModal from './staff-details.modal';
import './staff-search.css';
import { STAFF_DELETE_URL, STAFF_LIST_URL } from '../../api/URLs';
import { Delete, Edit } from '@mui/icons-material';
import { deleteDataRequest, getDataRequest } from '../../api/serviceMethods';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import TableFetchData from '../../components/data-table/TableFetchData';
import { useTranslation } from 'react-i18next';
import { ImageComponent } from '../../components/image/ImageComponent';
import DateComponent from '../../components/date/DateComponent';
import { EditOutlined, DeleteOutlined , EyeOutlined } from '@ant-design/icons';


function StaffSearchPage() {
  const [loading, setLoading] = useState(false);
  const [showStaff, setShowStaff] = useState({ show: false, staffId: '' });
  const [staffData, setStaffData] = useState([])
  const [searchResult, setSearchResult] = useState([]);
  const messageModalRef = useRef('')
  const navigate = useNavigate();
  const [keyword, setkeyword] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const {t} = useTranslation()

  const displayStaff = (staffId) => {
    setShowStaff({ show: true, staffId });
  };

  const tableStaffMangement = {
    appendable: false,
    removable: false,
    bordered: false,
    numbered: true,
    size: 'middle',
    pagination: {
      showLessItems: true,
      showSizeChanger: false,
      pageSize: 20,
    },
    columns: [
      {
        title: t('employee_Avatar'),
        render: (_, staff) => {
          return (
            <ImageComponent scr={staff.avatar} />
          )
        }
      },
      {
        title: t('employee_name'),
        render: (_, staff) => {
          return (
            <label className="text-capitalize">{staff && staff.name}</label>
          )
        }
      },
      {
        title: t('employee_email'),
        dataIndex: 'email',
      },
      {
        title: t('employee_Permission'),
        render: (_, staff) => {
          return (
            <div className="d-flex">
              <label className="text-capitalize">{staff?.role ? `${staff.role}` : ""}</label>
            </div>
          )
        }
      },
      {
        title: t('employee_Operation'),
        width: 120,
        render: (_, member) => {
          return (
            <div className="d-flex">
              <Switch className={`me-3`} defaultChecked />
              <EditOutlined className={`me-3`} style={{ cursor: "pointer", fontSize: '21px' }} onClick={() => {
                handleClickEdit(member)
                navigate(`/staff/create?id=${member.staffId}`)
              }} />
              <DeleteOutlined className={`me-3`} style={{ cursor: "pointer", fontSize: '21px', color: "#dc3545" }} onClick={() => deleteStaffData(member && member.staffId)} />
              <EyeOutlined className={`me-3`} style={{ cursor: "pointer", fontSize: '21px' }} onClick={() => {
                displayStaff(member.staffId);
              }} />
            </div>
          )
        },
      },
    ],
  };

  const formatStaffData = (staffData) => {
    let staffDataCopy = JSON.parse(JSON.stringify(staffData));
    staffDataCopy.forEach((staff) => {
      staff.name = staff.firstName + ' ' + staff.lastName;
    });
    setSearchResult(
      staffData.map((staff) => ({
        name: staff.firstName + ' ' + staff.lastName,
        number: staff.phone ? staff.phone.number : '',
        countryCode: staff.phone ? staff.phone.countryCode : '',
        email: staff.email || '',
        gender: staff.gender || '',
        staffId: staff.staffId || '',
        role: staff.role || '',
      }))
    );
  };

  useEffect(() => {
    filterTableResult('');
  }, [searchResult]);

  const filterTableResult = (value) => {
    const regex = new RegExp(value, 'i');
    const newStaffList = searchResult.filter((staff) => {
      return (
        regex.test(staff.number) ||
        regex.test(staff.countryCode) ||
        regex.test(staff.name) ||
        regex.test(staff.staffId) ||
        regex.test(staff.gender) ||
        regex.test(staff.email) ||
        regex.test(staff.role)
      );
    });
    setStaffData(newStaffList);
  };

  // for list all staff
  const initLoadStaffData = async () => {
    setLoading(true);
    await getDataRequest(`${STAFF_LIST_URL}`, {
      pageNo: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
    }).then((res) => {
      if (res.status === 200) {
        formatStaffData(res?.data);
      }
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.total,
        },
      });
      setLoading(false);
    }).catch((err) => {
      setLoading(false)
      console.log("Error", err)
    });
  };

  const deleteStaffData = async (item) => {
    messageModalRef.current.showWarningConfirm('Are you sure you want to delete this staff ?', async () => {
      await deleteDataRequest(`${STAFF_DELETE_URL}/${item}`).then((res) => {
        messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
        initLoadStaffData();
      }).catch((err) => {
        message.error("Error", err)
      });
    }, "", true);
  };

  // update data staff
  const handleClickEdit = (item) => {
    navigate("staff/create")
  }

  useEffect(() => {
    initLoadStaffData(keyword)
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     initLoadStaffData()
  //     setLoading(false)
  //   }, []);
  // }, [])

  return (
    <div className="container box-shadow h-100"
      style={{
        // background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        // padding: "32px",
        // overflow: "auto"
      }}>
      <StaffDetailsModal
        show={showStaff.show}
        staffId={showStaff.staffId}
        handleClose={() => setShowStaff({ show: false, staffId: '' })}
      />
      <div className="w-100 h-100 overflow-auto">
        <div className="w-100">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <div className="d-flex align-items-center col-6 row">
              <div className='col-6'>
                <Input
                  placeholder={t('employee_search')}
                  size="large"
                  disabled={loading}
                  loading={loading}
                  allowClear
                  onChange={(e) => {
                    setkeyword(e.target.value)
                    filterTableResult(e.target.value)
                  }}
                  onSearch={async (value) => {
                    filterTableResult(value);
                    setTableParams({
                      ...tableParams,
                      pagination: {
                        ...tableParams.pagination,
                        current: 1,
                        total: 0,
                      },
                    });
                    if (value === "") {
                      initLoadStaffData("");
                    } else {
                      initLoadStaffData(value);
                    }
                  }}
                />
              </div>
              <div className='col-6'>
                <DateComponent placeholder={t(`employee_date_plecholder`)} className={'w-100'} />
              </div>
            </div>
            <div className='d-flex alight-items-center'>
              <Button  size={'large'}
                onClick={() => {
                  initLoadStaffData(keyword);
                }}
              >
                {t('btnSearch')}
              </Button>
              <div className="me-2 ms-2 d-flex align-items-center" style={{ height: "100%", borderLeft: "1px solid rgba(220, 220, 220, 0.83)" }}></div>
              <Button className='btngoCreate'  size={'large'}
                // type="primary" 
                onClick={() => {
                  navigate(`/staff/create`)
                }}
              >
                {t('btnCreate')}
              </Button>
            </div>
          </div>
        </div>

        <div className="w-100" 
        // style={{ overflowY: "auto" }}
        >
          <TableFetchData
            tableParams={tableParams} setTableParams={setTableParams}
            data={staffData}
            columns={tableStaffMangement.columns}
            loading={loading} setLoading={setLoading}
          />
        </div>
      </div>
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </div>
  );
}

export default StaffSearchPage;
