import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { Col, Row } from 'antd';
import InputComponent from '../../components/input/InputComponent';
import InputNumberComponent from '../../components/input/InputNumberComponent';
import DateRangeComponent from '../../components/date/DateRangeComponent';
import SelectComponent from '../../components/select/SelectComponent';
import ButtontSubmitComponent from '../../components/buttonAnt/ButtontSubmitComponent';

function MemberShipCreatePage() {
  const [dates, setDates] = useState([null, null]);//useState([`2019-09-03`, `2019-11-22`]);
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false)
  const [loading, setloading] = useState(false);
  const [memberData, setMemberData] = useState([
    {
      label: 'Bio staff',
      value: 'Bio staff',
    }
  ])

  const [dataObject, setDataObject] = useState({
    member: null,
    memberType: '',
    status: null,
    amount: '',
    date: '',
  });

  const [memberTypeData, setMemberType] = useState([
    {
      label: 'Monthly',
      value: 'monthly',
    },
    {
      label: 'Yearly',
      value: 'yearly',
    },
  ]
  )

  const checkError = () => {
    if (!dataObject.member || !dataObject.memberType || !dataObject.status || !dataObject.amount || !dates[0] || !dates[1]) {
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    setIsSubmit(true)
    if (checkError()) {
      return false
    }
    console.log(`data: `, dataObject, dates)
    setIsSubmit(false)
  }

  return (
    <div className="container box-shadow h-100 mymain"
      style={{
        background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        padding: "32px",
        overflow: "auto"
      }}
    >
      <div className="w-100">
        <h2 className="text-start">Create Membership Management</h2>
      </div>
      <div className="w-100 bluediv">
        <div className="w-100 h-100 p-2 pt-3 ps-5 pe-5 d-flex flex-column justify-content-between" style={{ background: "#fff", borderRadius: "4px" }}>
          <div className='w-100'>
            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Member: </label>
                </div>
              </Col>
              <Col span={12}>
                <SelectComponent status={isSubmit && !dataObject.member && `error`} options={memberData} value={dataObject.member} setValue={(value) => setDataObject({ ...dataObject, member: value })} placeholder={`Select Member`} />
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Member Type: </label>
                </div>
              </Col>
              <Col span={12}>
                <SelectComponent status={isSubmit && !dataObject.memberType && `error`} options={memberTypeData} value={dataObject.memberType} setValue={(value) => setDataObject({ ...dataObject, memberType: value })} placeholder={`Select Member Type`} />
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Amount: </label>
                </div>
              </Col>
              <Col span={12}>
                <InputNumberComponent status={isSubmit && !dataObject.amount && `error`} min={0} value={dataObject.amount} setValue={(value) => setDataObject({ ...dataObject, amount: value })} placeholder={`Amount`} />
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Date: </label>
                </div>
              </Col>
              <Col span={12}>
                <DateRangeComponent status={isSubmit && (!dates[0] || !dates[1]) && `error`} value={dates ? dates : [null, null]} setValue={setDates} />
              </Col>
            </Row>
          </div>
          <Row className='mt-3'>
            <Col span={24}>
              <div className='text-center pb-3'>
                <ButtontSubmitComponent handle={() => navigate(`/membership`)} className={`me-5`} isCancel={true} title={`Back`} />
                <ButtontSubmitComponent handle={handleSubmit} title={`Submit`} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default MemberShipCreatePage;
