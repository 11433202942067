import { TextField } from '@mui/material';
import { Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MdCancel, MdCheck, MdEdit } from 'react-icons/md';
import './setting-input.css';

const { Text } = Typography;

const SettingTextAreaInput = ({ key, label, value, editable, editingRender }) => {
  const divStyle = {
    borderBottom: '1px solid rgba(210, 207, 207, 0.30)',
    padding: '8px 0',
    display: 'inline-block',
    width: '100%',
  };

  const iconStyle = {
    cursor: 'pointer',
  };

  const renderKeyValueElement = () => {
    return [
      <TextField
        name={label}
        value={currentValue}
        style={{ width: '100%', paddingInline: 12 }}
        multiline
        autoFocus
        variant="standard"
        onChange={(e) => {
          setCurrentValue(e.target.value);
        }}
      />,
      <Text>{currentValue}</Text>,
    ];
  };

  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  return (
    <div style={divStyle}>
      <Row key={key} className="d-flex align-items-center">
        <Col className="d-flex">
          {!!label && (
            <Text type="secondary" className="input-label">
              {label}
            </Text>
          )}
          <Text type="secondary" style={{ textAlign: 'end', width: 36 }}>
            {editable && (
              <>
                {isEditing ? (
                  <Space>
                    <MdCheck
                      className="icon-hover ok"
                      style={iconStyle}
                      onClick={() => {
                        setIsEditing(false);
                        editingRender.save && editingRender.save(currentValue);
                      }}
                    />
                    <MdCancel
                      className="icon-hover cancel"
                      style={iconStyle}
                      onClick={() => {
                        setIsEditing(false);
                        setCurrentValue(value);
                      }}
                    />
                  </Space>
                ) : (
                  <MdEdit style={{ cursor: 'pointer' }} onClick={() => setIsEditing(true)}></MdEdit>
                )}
              </>
            )}
          </Text>
        </Col>
      </Row>
      <Row key={key} className="d-flex align-items-center">
        {isEditing && editable ? renderKeyValueElement()[0] : renderKeyValueElement()[1]}
      </Row>
    </div>
  );
};

export default SettingTextAreaInput;
