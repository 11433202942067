import React from 'react';
import { Button } from 'antd';

const ButtontSubmitComponent = ({ title, handle, danger, className, type, isCancel, size, disabled }) => {
  // type= primary, dashed, text, link
  // size= middle, small, large
  return (
    <>
      {isCancel ? <Button
        style={{ minWidth: "8rem", paddingLeft: "5px", paddingRight: "5px" }}
        disabled={disabled ? disabled : false}
        className={`${className}`}
        onClick={handle && handle}
        size={size ? size : "large"}
      >{title}</Button>
        :
        <Button
          type={type ? type : "primary"}
          disabled={disabled ? disabled : false}
          size={size ? size : "large"}
          style={{ minWidth: "8rem", paddingLeft: "5px", paddingRight: "5px" }}
          className={`${className}`}
          danger={danger}
          onClick={handle && handle}
        >{title}</Button>}
    </>
  )
};
export default ButtontSubmitComponent;