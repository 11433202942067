import { Outlet } from 'react-router-dom';
import PageNotAccess from '../../pages/not-access/PageNotAccess';
import store from '../../stores';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const GuardedRoutes = ({ accessingRight, or, and }) => {
  const user = useSelector((state) => state.authenticatedUser);

  if (typeof accessingRight === 'string') {
    if (!accessingRight) {
      return <Outlet />;
    }

    return user && user.rightList.includes(accessingRight) ? <Outlet /> : <PageNotAccess />;
  } else {
    if (or) {
      return user && !!_.intersection(user.rightList, accessingRight).length ? <Outlet /> : <PageNotAccess />;
    } else if (and) {
      return user && accessingRight.every((element) => user.rightList.includes(element)) ? (
        <Outlet />
      ) : (
        <PageNotAccess />
      );
    } else {
      throw new Error('need logic');
    }
  }
};

export const AccessRight = (accessingRight) => {
  const user = store.getState().authenticatedUser;
  return user ? user.rightList.includes(accessingRight) : false;
};

export default GuardedRoutes;
