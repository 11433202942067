import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const SystemSettingsContainerPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    // {
    //   text: 'Group setting',
    //   route: 'group-settings',
    //   disable: false,
    // },
    // {
    //   text: 'License Agreement',
    //   route: 'license-agreement',
    //   disable: false,
    // },
    // {
    //   text: 'FAQ Chatbot',
    //   route: 'content-chatbot',
    //   disable: false,
    // },
    {
      text: 'Rights & Roles',
      route: 'rights-and-roles',
      disable: false,
    },
    // {
    //   text: 'Feature Flags',
    //   route: 'feature-flag',
    //   disable: false,
    // },
  ];

  useEffect(() => {
    if (!location.pathname.split('/')[2]) {
      navigate(tabs[0].route);
    }
  }, [location]);

  return (
    <>
      <Outlet />
      {/* <Tabs
        activeKey={location.pathname.split('/')[2]}
        onTabClick={async (key) => {
          navigate(`${key}`);
        }}
        style={{
          marginLeft: '16px',
          marginBottom: '20px',
          marginRight: '16px',
        }}
        items={tabs.map((tab) => {
          return {
            key: tab.route,
            label: tab.text,
            disabled: tab.disable,
          };
        })}
      />
      <div style={{ marginBottom: 20 }}>
        <Outlet />
      </div> */}
    </>
  );
};

export default SystemSettingsContainerPage;
