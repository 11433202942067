import { Card, List, Modal, notification, Result } from "antd";
import { ContentCopy, EditOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NewPostPreviewPage from "../NewPostPreviewPage";

const NewsPostsListPage = () => {
    const navigate = useNavigate();
    const newsPosts = useSelector(state => state.newsPosts);

    const [previewModal, setPreviewModal] = useState({ show: false, post: null });

    return (
        <div className="post-list-panel">
            <Card hoverable title={"Create New Post"} onClick={() => navigate('create-new')}
                style={{ width: 220, marginBottom: 20 }}
            >
                Click To Create New Post
            </Card>

            {
                newsPosts && !newsPosts.error && !newsPosts.error &&
                <List
                    loading={newsPosts && !newsPosts.initialized}
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 3,
                    }}
                    dataSource={newsPosts && newsPosts.data || []}
                    renderItem={item => (
                        <List.Item>
                            <Card hoverable onClick={() => {
                                setPreviewModal({
                                    show: true,
                                    post: item
                                })
                            }}
                                cover={<img alt="coverImage"
                                    style={{ objectFit: "contain", objectPosition: "top", height: "175px" }}
                                    src={item.coverimage} />}
                                actions={[
                                    <div onClick={e => {
                                        e.stopPropagation();
                                        navigate(`edit/${item.newsId}`);
                                    }}>
                                        <EditOutlined /> Edit
                                    </div>,
                                    <div onClick={e => {
                                        e.stopPropagation();
                                        navigate(`templating-from/${item.newsId}`);
                                    }}>
                                        <ContentCopy /> Use as template
                                    </div>,
                                ]}
                            >
                                <Card.Meta title={item.localize.en.title}
                                    description={
                                        <>
                                            {
                                                item.localize.en.subtitle
                                            }
                                            <div
                                                style={{ textAlign: 'right', fontStyle: 'italic' }}>{item.newsId}</div>
                                        </>
                                    } />
                            </Card>
                        </List.Item>
                    )}
                >
                </List>
            }

            {
                newsPosts && newsPosts.error &&
                <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                />
            }

            <Modal
                destroyOnClose
                open={previewModal.show}
                footer={null}
                onCancel={() => setPreviewModal({ show: false, post: null })}
                style={{ top: 0 }}
            >
                <NewPostPreviewPage currentPost={previewModal.post} currentLanguage={'en'} />
            </Modal>
        </div>
    )
}

export default NewsPostsListPage;