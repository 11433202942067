import { Avatar, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { BsPerson } from 'react-icons/bs';
import { FaBirthdayCake } from 'react-icons/fa';
import {
  MdAssignmentInd,
  MdDriveFileRenameOutline,
  MdLanguage,
  MdOutlineDescription,
  MdOutlineEmail,
  MdPhone,
} from 'react-icons/md';
import SettingInput from '../../components/setting/SettingInput';
import SettingTextAreaInput from '../../components/setting/SettingTextAreaInput';
import './staff.css';
import { STAFF_DETAIL_URL } from '../../api/URLs';
import { getDataRequest } from '../../api/serviceMethods';

const StaffDetailsModal = (props) => {
  const { staffId, show, handleClose } = props;
  const [loading, setLoading] = useState(true);
  const [currentStaff, setCurrentStaff] = useState(null);
  const { Text } = Typography;


  useEffect(() => {
    if (!!staffId) {
      (async () => {
        try {
          const staffData = await getDataRequest(`${STAFF_DETAIL_URL}/${staffId && staffId}`)
          setCurrentStaff(staffData);
        } catch (err) {
          console.log(err);
          alert('Fail to get staff!');
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [staffId]);

  return (
    <>
      {currentStaff && (
        <Modal show={!loading && show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center" title="Staff Role">
              {currentStaff.role.name}
            </Row>

            <Modal.Title id="staff-modal-title">
              <Row className="justify-content-center">
                <Avatar size={87} src={currentStaff.coverImage} />
              </Row>
              <Row className="justify-content-center">{currentStaff.name}</Row>
            </Modal.Title>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdDriveFileRenameOutline className="staff-detail-icon" />
                  <SettingInput
                    key={0}
                    label={'First Name'}
                    value={currentStaff.firstName}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdDriveFileRenameOutline className="staff-detail-icon" />
                  <SettingInput
                    key={1}
                    label={'Last Name'}
                    value={currentStaff.lastName}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdDriveFileRenameOutline className="staff-detail-icon" />
                  <SettingInput
                    key={2}
                    label={'Title'}
                    value={currentStaff.title}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdOutlineEmail className="staff-detail-icon" />
                  <SettingInput
                    key={3}
                    label={'Email Address'}
                    value={currentStaff.email}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdPhone className="staff-detail-icon" />
                  <SettingInput
                    key={4}
                    label={'Phone Number'}
                    value={currentStaff.phone?.number}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <FaBirthdayCake className="staff-detail-icon" />
                  <SettingInput
                    key={5}
                    label={'Date of Birth'}
                    value={new Date(currentStaff.dob).toISOString().split('T')[0]}
                    editingRender={{
                      elementType: 'date',
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div className="staff-detail">
                  <BsPerson className="staff-detail-icon" />
                  <SettingInput
                    key={6}
                    label={'Gender'}
                    value={currentStaff.gender || ''}
                    editingRender={{
                      elementType: 'dropdown',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdLanguage className="staff-detail-icon" />
                  <SettingInput
                    key={7}
                    label={'Languages'}
                    value={currentStaff.languages ? currentStaff.languages.join(', ') : ''}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdAssignmentInd className="staff-detail-icon" />
                  <SettingInput
                    key={7}
                    label={'Role'}
                    value={currentStaff.role.name}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <div id="description">
                {currentStaff.description?.map((detail, i) => {
                  return (
                    <div className="staff-detail">
                      <MdOutlineDescription className="staff-detail-icon" />
                      <SettingInput
                        key={i + 28}
                        label={`Description ${i + 1}`}
                        value={detail}
                        editingRender={{
                          elementType: 'text',
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <SettingTextAreaInput
                    key={7}
                    label={'Introduction'}
                    value={currentStaff.introduction}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default StaffDetailsModal;
