import { Affix, Typography } from 'antd';
import { AiOutlineHeart, AiOutlineLeft } from 'react-icons/ai';
import moment from 'moment/moment';
import React from 'react';

const NewPostPreviewPage = ({ currentPost, currentLanguage }) => {
  const generateRealtimeRender = (arr) => {
    const capitalizeFirstLetter = (str) => {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    };

    const fontStyle = (content, heading) => {
      let size = '16px';
      if (content.size === 'small') {
        size = '14px';
      } else if (content.size === 'xsmall') {
        size = '12px';
      }

      const result = {
        fontWeight: content.fontWeight,
        textDecoration: content.textDecoration,
        fontSize: size,
      };

      if (heading) {
        delete result.fontWeight;
        delete result.fontSize;
      }

      return result;
    };

    const result = [];

    for (let i = 0; i < arr.length; ++i) {
      switch (arr[i].tag) {
        case 'body':
          result.push(
            <div style={{ marginBottom: arr[i].marginBottom || 0, ...fontStyle(arr[i], false) }}>{arr[i].text}</div>
          );
          break;
        case 'bullet':
          const lis = [
            <li style={{ marginBottom: arr[i].marginBottom || 0, ...fontStyle(arr[i], false) }}>
              {capitalizeFirstLetter(arr[i].text)}
            </li>,
          ];
          while (arr[i + 1]) {
            if (arr[i + 1].tag === 'bullet') {
              ++i;
              lis.push(
                <li style={{ marginBottom: arr[i].marginBottom || 0, ...fontStyle(arr[i], false) }}>
                  {capitalizeFirstLetter(arr[i].text)}
                </li>
              );
            } else {
              break;
            }
          }
          result.push(<ul>{lis}</ul>);
          break;
        case 'h1':
          result.push(
            <p
              style={{
                marginBottom: arr[i].marginBottom || 0,
                fontWeight: 'bold',
                fontSize: '24px',
                ...fontStyle(arr[i], true),
              }}
            >
              {capitalizeFirstLetter(arr[i].text)}
            </p>
          );
          break;
        case 'h2':
          result.push(
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
                marginBottom: arr[i].marginBottom || 0,
                ...fontStyle(arr[i], true),
              }}
            >
              {capitalizeFirstLetter(arr[i].text)}
            </p>
          );
          break;
        case 'h3':
          result.push(
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: arr[i].marginBottom || 0,
                ...fontStyle(arr[i], true),
              }}
            >
              {capitalizeFirstLetter(arr[i].text)}
            </p>
          );
          break;
        case 'h4':
          result.push(
            <p
              style={{
                fontSize: '16px',
                marginBottom: arr[i].marginBottom || 0,
                ...fontStyle(arr[i], true),
              }}
            >
              {capitalizeFirstLetter(arr[i].text)}
            </p>
          );
          break;
        case 'h5':
          result.push(
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                marginBottom: arr[i].marginBottom || 0,
                ...fontStyle(arr[i], true),
              }}
            >
              {capitalizeFirstLetter(arr[i].text)}
            </p>
          );
          break;
        case 'button':
          result.push(
            <div className="d-flex flex-row justify-content-center align-items-center">
              <button
                style={{
                  marginBottom: arr[i].marginBottom || 0,
                  border: '1px steelblue solid',
                  borderRadius: 5,
                  background: 'steelblue',
                  color: 'white',
                  fontWeight: 'bold',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px',
                }}
              >
                {(arr[i].title || '').toUpperCase()}
              </button>
            </div>
          );
          break;
        case 'img':
          result.push(
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                src={arr[i].url}
                alt={arr[i].url}
                style={{
                  marginBottom: arr[i].marginBottom || 0,
                  aspectRatio: arr[i].ratio ? arr[i].ratio : 'auto',
                  width: arr[i].imgwidth ? arr[i].imgwidth + '%' : 'auto',
                }}
              />
            </div>
          );
          break;
        case 'accordion':
          const children = generateRealtimeRender(arr[i].accordion);
          const rgn = Math.random();
          const wrapper = (
            <div className="mock-accordion">
              <div>
                <input type="checkbox" id={'section' + rgn} className="mock-accordion-input" />
                <label htmlFor={'section' + rgn} className="mock-accordion-label">
                  {arr[i].header}
                </label>
                <div className="mock-accordion-content">{children}</div>
              </div>
            </div>
          );
          result.push(wrapper);
          break;
        case 'linkbutton':
          result.push(
            <div>
              <span
                style={{
                  color: 'blue',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginBottom: arr[i].marginBottom || 0,
                }}
              >
                {arr[i].title}
              </span>
            </div>
          );
          break;
      }
    }

    return result;
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div style={{ margin: '5px 10px' }}>
        <Typography.Text strong>
          It is not the real rendering on user's phone but an illustration for your reference
        </Typography.Text>
      </div>
      <Affix offsetTop={10}>
        <div
          style={{
            width: 370,
            height: 844,
            border: 'grey 2px solid',
            borderRadius: '28px',
            paddingTop: '40px',
            overflowY: 'auto',
          }}
        >
          <div className="newsposts-emulator-header">
            <p
              style={{
                marginLeft: 10,
                display: 'inline-block',
                width: 310,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textAlign: 'center',
              }}
            >
              <AiOutlineLeft size="1.5em" style={{ float: 'left' }} />
              <span
                style={{
                  fontSize: '18px',
                  marginLeft: 20,
                  fontWeight: 'bold',
                }}
              >
                {currentPost.localize ? currentPost.localize[currentLanguage]?.title?.toUpperCase() || '' : ''}
              </span>
            </p>
          </div>
          <div>
            <img src={currentPost.coverimage || 'https://dummyimage.com/388'} style={{ width: 366 }} alt="" />
          </div>
          <div className="newsposts-emulator-content">
            <div
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                display: 'inline-block',
                width: 300,
              }}
            >
              <div>{currentPost.localize ? currentPost.localize[currentLanguage]?.title : ''}</div>
              <Typography.Text type="secondary" style={{ fontSize: 'small' }}>
                {moment(currentPost.publishTS).format('MMMM DD, YYYY')}
              </Typography.Text>
            </div>
            <span className="float-end">
              <AiOutlineHeart size="1.5em" />
            </span>
            <hr />
            <div>
              {currentPost.localize
                ? generateRealtimeRender(currentPost.localize[currentLanguage]?.layout || [])
                : null}
            </div>
          </div>
        </div>
      </Affix>
    </div>
  );
};

export default NewPostPreviewPage;
