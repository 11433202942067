import GroupsIcon from '@mui/icons-material/Groups';
import { CardMembership, ImportContacts, Newspaper, Payment, School, SettingsSuggest, ShoppingCart, ThumbUpOffAlt } from '@mui/icons-material';
import { ApplicationRight } from "../../share/RightList"
import _ from 'lodash';
import { LaptopOutlined, FileTextOutlined, NotificationOutlined, ReadOutlined, AuditOutlined, UserOutlined, TeamOutlined } from '@ant-design/icons';


export const menus = (user) => {

  let menusNew = [
    {
      title: 'User Management',
      pathname: "/member",
      icon: null,
      accessingRights: [
        ApplicationRight.Member_Access,
      ],
      key: 1,
      submenu: [
        {
          title: 'User Management',
          pathname: "/member",
          icon: <UserOutlined color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
          accessingRights: [
            ApplicationRight.Member_Access,
          ],
          key: 1.1,
          submenu: null
        },
        // {
        //   title: 'MemberShip',
        //   pathname: "/member",
        //   icon: <CardMembership color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
        //   accessingRights: [
        //     ApplicationRight.Member_Access,
        //   ],
        //   key: 1.1,
        //   submenu: null
        // },
      ]
    },
    {
      title: 'Payment Management',
      pathname: "/payment",
      icon: null,
      accessingRights: [
        ApplicationRight.Payment_Access,
      ],
      key: 1,
      submenu: [
        {
          title: 'Payment Management',
          pathname: "/payment",
          icon: <AuditOutlined color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
          accessingRights: [
            ApplicationRight.Payment_Access,
          ],
          key: 1.1,
          submenu: null
        },
      ]
    },
    // {
    //   title: 'Membership Management',
    //   pathname: "/membership",
    //   icon: null,
    //   accessingRights: [],
    //   key: 1,
    //   submenu: [
    //     {
    //       title: 'Membership',
    //       pathname: "/membership",
    //       icon: <CardMembership color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
    //       accessingRights: [
    //         ApplicationRight.Payment_Access,
    //       ],
    //       key: 1.1,
    //       submenu: null
    //     },
    //   ]
    // },
    // {
    //   title: 'Education Management',
    //   pathname: "/education-courses",
    //   icon: null,
    //   accessingRights: [],
    //   key: 1,
    //   submenu: [
    //     {
    //       title: 'Education Courses',
    //       pathname: "/education-courses",
    //       icon: <School color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
    //       accessingRights: [
    //         ApplicationRight.Payment_Access,
    //       ],
    //       key: 1.1,
    //       submenu: null
    //     },
    //   ]
    // },
    {
      title: 'Magazine Management',
      pathname: "/magazine",
      icon: null,
      accessingRights: [
        ApplicationRight.Magazine_Access,
      ],
      key: 1,
      submenu: [
        {
          title: 'Magazine Management',
          pathname: "/magazine",
          icon: <ReadOutlined color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
          accessingRights: [
            ApplicationRight.Magazine_Access,
          ],
          key: 1.1,
          submenu: null
        },
      ]
    },
    {
      title: 'News Management',
      pathname: "/news-posts",
      icon: null,
      accessingRights: [
        ApplicationRight.Magazine_Access,
      ],
      key: 1,
      submenu: [
        {
          title: 'News Management',
          pathname: "/news-posts",
          icon: <FileTextOutlined color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
          accessingRights: [
            ApplicationRight.Magazine_Access,
          ],
          key: 1.1,
          submenu: null
        },
        // {
        //   title: 'Likes',
        //   pathname: "/likes",
        //   icon: <ThumbUpOffAlt color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
        //   accessingRights: [
        //     ApplicationRight.Magazine_Access,
        //   ],
        //   key: 1.1,
        //   submenu: null
        // },
      ]
    },
    // {
    //   title: 'Shopping Management',
    //   pathname: "/shopping",
    //   icon: null,
    //   accessingRights: [],
    //   key: 1,
    //   submenu: [
    //     {
    //       title: 'shopping',
    //       pathname: "/shopping",
    //       icon: <ShoppingCart color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
    //       accessingRights: [
    //         ApplicationRight.Magazine_Access,
    //       ],
    //       key: 1.1,
    //       submenu: null
    //     },
    //   ]
    // },
    {
      title: 'Employee Management',
      pathname: "/staff/search",
      icon: null,
      accessingRights: [
        ApplicationRight.Staff_Management_Access,
        ApplicationRight.System_Setting_Access,
      ],
      key: 1,
      submenu: [
        {
          title: 'Employee Management',
          pathname: "/staff/search",
          icon: <TeamOutlined color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
          accessingRights: [
            ApplicationRight.Staff_Management_Access,
          ],
          key: 1.1,
          submenu: null
        },
        // {
        //   title: 'System Setting',
        //   pathname: "/system-settings/rights-and-roles",
        //   icon: <SettingsSuggest color="white" sx={{ color: 'white', width: `16px`, fontSize: "2rem" }} />,
        //   accessingRights: [
        //     ApplicationRight.System_Setting_Access,
        //   ],
        //   key: 1.1,
        //   submenu: null
        // },
      ]
    },
  ]

  const NavItem = (accessingRights) => {
    const hasRight = accessingRights && accessingRights.length ? !!_.intersection(user.rightList, accessingRights).length : true;
    return hasRight ? hasRight : false
  };

  const SiderNavSection = (accessingRights) => {
    const hasRight = accessingRights && accessingRights.length ? !!_.intersection(user.rightList, accessingRights).length : true;
    return hasRight ? hasRight : false
  };

  let menusN = []
  menusNew.forEach(it => {
    if(SiderNavSection(it.accessingRights)) {
      let itNew = {...it}
      let submenu = []
      it.submenu && it.submenu.forEach(its => {
        if(NavItem(its.accessingRights)) {
          submenu.push(its)
        }
      });
      if(itNew.submenu) {
        if(submenu.length > 0) {
          itNew.pathname = submenu[0] && submenu[0].pathname
        }
        itNew.submenu = [...submenu]
      }
      menusN.push({...itNew})
    }
  });

  return menusN
}

