import { notification, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import './news-posts.page.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setNewsPostLoadError, setNewsPostStoreData } from '../../../stores/news-posts.store';
import { NEWS_POSTS_URL } from '../../../api/URLs';
import esiApi from '../../../api/esiApi';

const NewsPostsPageMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appUrl = useLocation();
  const [step, setStep] = useState(0);
  const newsPosts = useSelector((state) => state.newsPosts);

  useEffect(() => {
    if (newsPosts && !newsPosts.initialized) {
      (async () => {
        let isError = false;
        let response;
        try {
          response = await esiApi.get(NEWS_POSTS_URL);

          if (response.data.status !== 200) {
            notification.error({
              message: 'Error!',
              description: 'Unable to get news posts data',
              duration: 0,
            });
            isError = true;
          }
        } catch (error) {
          notification.error({
            message: 'Error!',
            description: 'Systematic error happens during news posts data retrieval',
            duration: 0,
          });
          isError = true;
        } finally {
          if (isError) {
            dispatch(setNewsPostLoadError());
          } else {
            dispatch(
              setNewsPostStoreData({
                data: response.data.data.posts,
                locationData: response.data.data.locations,
              })
            );
          }
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (!appUrl.pathname.split('/')[2]) {
      setStep(0);
    } else {
      setStep(1);
    }
  }, [appUrl]);

  return (
    <div
      className="news-posts-page h-100 overflow-auto"
      style={{
        // background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        // padding: "32px",
      }}
    >
      <Steps
        type="navigation"
        current={step}
        onChange={(newCurrent) => {
          setStep(newCurrent);
          if (newCurrent === 0) {
            navigate('/news-posts');
          }
        }}
      >
        <Steps.Step key="choose" title="Posts" subTitle="Create new post or Choose the post you want to modify" />
        <Steps.Step key="modify" title="Content" subTitle={`Compose the post content`} disabled={step !== 1} />
      </Steps>

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default NewsPostsPageMain;
