export const ApplicationRight = {
  
  // New Access
  Member_Access: 'Member_Access',
  Payment_Access: 'Payment_Access',
  Magazine_Access: 'Magazine_Access',
  News_Posts_Access: 'News_Posts_Access',
  Staff_Management_Access: 'Staff_Management_Access',
  System_Setting_Access: 'System_Setting_Access',
};
