import React from 'react';
import { Tabs } from 'antd';
import './tabs.css'
import TableUserMember from '../../pages/member-pages/TableUser';
import { useTranslation } from 'react-i18next';
import TableMember from '../../pages/member-pages/TableMember';
const TableUserManagerment = ({ messageModalRef }) => {
    const { t } = useTranslation();
    return (
        <Tabs defaultActiveKey="listUsers" className='cusStyle'>
            <Tabs.TabPane tab={t("User List")} key="listUsers">
                <TableUserMember messageModalRef={messageModalRef} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Member List")} key="memberList">
                <TableMember messageModalRef={messageModalRef} />
            </Tabs.TabPane>
        </Tabs>
    )
}
export default TableUserManagerment;