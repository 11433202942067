import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const DateComponent = ({ isDateTime, size, status, format, type, value, setValue, placeholder, textError, width, isRangePicker = true, className }) => {
    // status= error, warning, normal
    const onChange = (date, dateString) => {
        setValue && setValue(dateString);
    };

    return (
        <>
            <DatePicker
                style={{width : width ? width : ''}}
                format={{
                    format: format ? format : 'MM-DD-YYYY',
                    type: type ? type : 'mask',
                }}
                size={size ? size : "large"}
                status={status ? status : "normal"}
                placeholder={placeholder ? placeholder : 'Select date'}
                value={value ? dayjs(value) : null}
                onChange={onChange}
                className={className ? className :""}
            />
            {textError && (
                <div className="ant-form-item-explain-error" style={{ color: "#ff4d4f" }}>
                    {textError}
                </div>
            )}
        </>
    );
};

export default DateComponent;
