export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateErrorObjectArray = (dataValid, ArrayData) => {

    // let dataValid = {
    //   "name": "name...",
    // }

    // let ArrayData = dataSource.map(it => ({
    //   "name": "name...",
    // }))

    const isAnyFieldEmpty = (obj) => {
        return Object.values(obj).some(value => value === null || value === undefined || value === "" || !value);
    };

    if (dataValid && isAnyFieldEmpty(dataValid)) {
        return true
    }

    if(ArrayData && ArrayData.length === 0) {
        return true
    }
    if (ArrayData && ArrayData.length) {
        let prescriptionOrderValid = ArrayData.filter(it => isAnyFieldEmpty(it))
        if (prescriptionOrderValid.length > 0) {
            return true
        }
    }

    return false
}