import { createSlice } from '@reduxjs/toolkit';

const timezoneSlice = createSlice({
  name: 'timezone',
  initialState: null,
  reducers: {
    setTimezone: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTimezone } = timezoneSlice.actions;
export default timezoneSlice.reducer;
