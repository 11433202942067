import React, { useState } from 'react';
import { Button, Col, Modal, Row, Spin } from 'antd';
import SelectComponent from '../../../../components/select/SelectComponent';
import InputComponent from '../../../../components/input/InputComponent';
import TextAreaComponent from '../../../../components/input/TextAreaComponent';
import { postDataRequest, putDataRequest } from '../../../../api/serviceMethods';
import { AUTHORIZATION_RIGHT_URL } from '../../../../api/URLs';
import { validateErrorObjectArray } from '../../../../utils/Validate';

const RightsModal = ({ open, setOpen, getAllDataRequest, allRightsList, messageModalRef }) => {
  const [isSubmit, setIsSubmit] = useState(false)
  const [loading, setLoading] = useState(false);
  const [dataObject, setDataObject] = useState({
    "name": open && open.name || "",
    "group": open && open.group || "",
    "description": open && open.description || "",
    "id": open && open.name || ""
  });

  const validateError = () => {
    if(
      validateErrorObjectArray({
        "name": dataObject.name,
        "description": dataObject.description
      })
    ) {
      return true
    }
    return false
  }

  const paramsData = () => {

    let params = {
      "name": dataObject.name,
      "group": dataObject.group,
      "description": dataObject.description
    }
    return params
  }

  const handleSubmit = async () => {
    setIsSubmit(true)

    if (validateError()) {
      messageModalRef && messageModalRef.current.showWarningConfirmsAutoClose(`Please fill in all required items before submitting`, () => { }, "", true);
      return false
    }

    let params = paramsData()
    setLoading(true)
    await postDataRequest(AUTHORIZATION_RIGHT_URL, params)
      .then((res) => {
        if (res) {
          getAllDataRequest && getAllDataRequest()
          setLoading(false)
          messageModalRef && messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message || `Successfully`, () => { }, "", true);
          setOpen(false)
          return true;
        } else {
          setLoading(false)
          messageModalRef && messageModalRef.current.showWarningConfirmsAutoClose(res && res.message || `Failed`, () => { }, "", true);
          return false;
        }
      })
      .catch((error) => {
        setLoading(false)
        messageModalRef && messageModalRef.current.showWarningConfirmsAutoClose(error && error || `Failed`, () => { }, "", true);
        return false;

      })

  }


  const handleEdit = async () => {
    setIsSubmit(true)

    if (validateError()) {
      messageModalRef && messageModalRef.current.showWarningConfirmsAutoClose(`Please fill in all required items before submitting`, () => { }, "", true);
      return false
    }

    let params = paramsData()
    setLoading(true)
    await putDataRequest(`${AUTHORIZATION_RIGHT_URL}/${open.name}`, params)
      .then((res) => {
        if (res) {
          getAllDataRequest && getAllDataRequest()
          setLoading(false)
          messageModalRef && messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message || `Successfully`, () => { }, "", true);
          setOpen(false)
          return true;
        } else {
          setLoading(false)
          messageModalRef && messageModalRef.current.showWarningConfirmsAutoClose(res && res.message || `Failed`, () => { }, "", true);
          return false;
        }
      })
      .catch((error) => {
        setLoading(false)
        messageModalRef && messageModalRef.current.showWarningConfirmsAutoClose(error && error || `Failed`, () => { }, "", true);
        return false;

      })

  }

  return (
    <>
      <Modal
        title={<>
          <div className='text-center' style={{ fontSize: "1.3rem" }}>{open.name ? `Edit Right Management` : `Create Right Management`}</div>
        </>}
        open={open}
        cancelText={"Cancel"}
        okText={"Submit"}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        keyboard={false}

        width={900}
        footer={[
          <div className='justify-content--around d-flex pt-2 justify-content-center'>
            <Button className='me-5' key="cancel" size={'large'} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button size={'large'} type="primary" disabled={loading} onClick={open.name ? handleEdit: handleSubmit}>
              Submit
            </Button>
          </div>
        ]}
      >
        <Spin spinning={loading}>

          <Row>
            <Col span={18}>
            </Col>
            <Col span={6} className='text-end'>

            </Col>
          </Row>

          <div className='w-100'>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Right Name: </label>
                </div>
              </Col>
              <Col span={18}>
                <InputComponent disabled={open && open.name} className={`mb-2`} status={isSubmit && !dataObject.name && `error`} value={dataObject.name}
                  setValue={(value) => setDataObject({ ...dataObject, name: value })} placeholder={`Right Name`} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Group: </label>
                </div>
              </Col>
              <Col span={18}>
                <SelectComponent disabled={open && open.name} options={allRightsList} value={dataObject.group}
                  setValue={(value, values) => {
                    setDataObject({ ...dataObject, group: value })
                  }} placeholder={`Select Group`} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Description: </label>
                </div>
              </Col>
              <Col span={18}>
                <TextAreaComponent status={isSubmit && !dataObject.description && `error`} value={dataObject.description} setValue={(value) => setDataObject({ ...dataObject, description: value })} placeholder={`Description`} />
              </Col>
            </Row>
          </div>
        </Spin>

      </Modal>
    </>
  );
};
export default RightsModal;