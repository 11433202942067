import { AutoGraph } from "@mui/icons-material";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const DashboardComponent = () => {
    const { t } = useTranslation();
    const dashboardData = [
        {
            title: t("Total Users"),
            total: "2,584",
            rate: ""
        },
        {
            title: t("Paid Membership"),
            total: "665",
            rate: ""
        },
        {
            title: t("New Users Per Day"),
            total: "29",
            rate: "23"
        },
        {
            title: t("Member Conversion Rate"),
            total: "1.2%",
            rate: "11"
        }
    ]
    return (
        <Row >
            {
                dashboardData.map((item, ind) => (
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <div className="d-flex" style={{ padding: "1.5rem 3rem", justifyContent: "space-between" }}>
                            <Row
                                className="text-center"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start"
                                }}
                            >
                                <Col style={{ color: "#636e88" }}>{item.title}</Col>
                                <Col style={{ fontWeight: 600, fontSize: "32px" }}>
                                    {item.total}
                                    {
                                        item.title === "New Users Per Day" || item.title === "日新增用户" || item.title === "Member Conversion Rate" || item.title === "会员转化率" ?
                                            <>
                                                <AutoGraph style={{ marginLeft: "10px", color: "#45b887" }} />
                                                <span style={{ fontSize: "16px", marginLeft: "10px", color: "#636e88" }}>{item.rate}%</span>
                                            </>
                                            : ""
                                    }
                                </Col>
                            </Row>
                            {
                                item.title === "Member Conversion Rate" || item.title === "会员转化率" ? "" :
                                    <div className="vr" style={{ height: "70px", borderLeft: "1px solid #000" }}></div>
                            }
                        </div>
                    </Col>
                ))
            }
        </Row>
    );
};

export default DashboardComponent;
