import lrz from 'lrz';
import moment from 'moment';
import momentTZ from 'moment-timezone'

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const canvas = document.createElement('canvas');
        const img = document.createElement('img');
        img.src = reader.result;
        // console.log(reader.result);
        img.onload = () => {
            const ctx = canvas.getContext('2d');
            let originImageWidth = img.width, originImageHeight = img.height;
            let maxHeight = 1024;
            let targetWidth = originImageWidth, targetHeight = originImageHeight;
            if (originImageHeight > maxHeight) {
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originImageWidth / originImageHeight));
            }
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx.clearRect(0, 0, targetWidth, targetHeight);
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
            const imageDataURL = canvas.toDataURL(file.type || 'image/jpeg', 1);
            const imageBytes = window.atob(imageDataURL.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(imageBytes.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < imageBytes.length; i++) {
                uint8Array[i] = imageBytes.charCodeAt(i);
            }
            let mimeType = imageDataURL.split(',')[0].match(/:(.*?);/)[1];
            let newFile = new File([uint8Array], file.name, { type: mimeType || 'image/jpeg' });
            var quality = 1;
            if (newFile.size > 1024 * 1024) {
                quality = 0.6;
            }
            lrz(newFile, { quality: quality }).then((rst) => {
                resolve(rst.base64);
            })
        };
    };
    reader.onerror = error => reject(error);
});

export const DATE_FORMAT = 'YYYY-MM-DD';

export const day = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}

export const dayShort = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
}

export const month ={
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}

export const monthShort ={
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
}

export function FeedDateToString(date) {
  var d = new Date(date);
  return d.getDate() + '-' + monthShort[d.getMonth()] + '-' + d.getFullYear();
}

export function GetDefaultFromDate() {
  // var mydate = moment().tz("America/Los_Angeles").subtract('30', 'days').format(DATE_FORMAT);
  // return mydate;
  return '';
}

export function GetDefaultToDate() {
  // var mydate = moment().tz("America/Los_Angeles").format(DATE_FORMAT);
  // return mydate;
  return '';
}

export function ConvertToUTCDate(dateString) {
  return dateString.substr(0, 10);
}

export function FormatDateToString(date, isutc) {
  if (isutc) {
    return date.substr(0, 10);
  }
  var mydate = momentTZ.tz(date, "America/New_York").format('YYYY-MM-DD');
  return mydate;
}

export function getCurrentDateString() {
  var date = moment().format()
  return date.substr(0, 10);
}

export function getTimeBetween(date1, date2) {
  let usedTime = date2 - date1;
  let days = Math.floor(usedTime / (24 * 3600 * 1000));
  let leavel = usedTime % (24 * 3600 * 1000); 
  let hours = Math.floor(leavel / (3600 * 1000)); 
  return days + ' days ' + hours + ' hrs';
}

export function getNextDateString(date, nextday) {
  date.setDate(date.getDate() + nextday);
  var year = date.getFullYear().toString();
  var month = (date.getMonth() + 1).toString();
  var daystring = date.getDate().toString();
  if (month.length === 1) {
      month = '0' + month;
  }
  if (daystring.length === 1) {
      daystring = '0' + daystring;
  }
  return year + '-' + month + '-' + daystring;
}

export function getCurrentDateTimeString() {
  var date = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  return date;
}

export function StrToDate(str) {
    let date = new Date(str)
    return (new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000));
}

export function DateToStr(date) {
    return ((new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, 10));
}

export function DateToStr1(date) {
    var d = new Date(date);
    var year = '' + d.getFullYear();
    return (
        monthShort[d.getMonth()] +
        ' ' +
        year.substr(year.length - 2, 2)
    );
}

export function formatNumber(data) {
    if (data === '-' || data === null) {
        return data;
    }
    var usdata = parseInt(data);
    var than0 = true;
    if (usdata < 0) {
        than0 = false;
        usdata = -1 * usdata;
    }
    var usstr = '' + usdata;
    var result = '';
    while (usstr.length > 3) {
        if (result.length === 0) {
            result = usstr.substr(usstr.length - 3, 3);
        } else {
            result = usstr.substr(usstr.length - 3, 3) + ',' + result;
        }
        usstr = usstr.substr(0, usstr.length - 3);
    };
    if (usstr.length > 0) {
        if (result.length > 0) {
            result = usstr + ',' + result;
        } else {
            result = usstr;
        }
    }
    return than0 ? result : '-' + result;
}

export function pointToUSStr(data) {
    if (data === '-' || data === null) {
        return data;
    }
    var usdata = parseInt(data / 100);
    var than0 = true;
    if (usdata < 0) {
        than0 = false;
        usdata = -1 * usdata;
    }
    var usstr = '' + usdata;
    var result = '';
    while (usstr.length > 3) {
        if (result.length === 0) {
            result = usstr.substr(usstr.length - 3, 3);
        } else {
            result = usstr.substr(usstr.length - 3, 3) + ',' + result;
        }
        usstr = usstr.substr(0, usstr.length - 3);
    };
    if (usstr.length > 0) {
        if (result.length > 0) {
            result = usstr + ',' + result;
        } else {
            result = usstr;
        }
    }
    return than0 ? '$' + result : '-$' + result;
}

export const isLike = (a, b) => {
    try {
        return a.toLowerCase().indexOf(b.toLowerCase()) > -1
    } catch (e) {
        return false
    }
}
