export const LOGIN_URL = '/auth/login';
export const MEMBER_URL = '/members'
export const TREATMENT_FROM_ME_URL = '/auth/login';
export const TREATMENT_NEED_MY_REVIEW_URL = '/auth/login';
export const UPLOAD_IMAGE_URL = '/upload';
export const UPLOAD_IMAGE_URLNew = '/utils/upload-image';
export const NEWS_POSTS_URL = '/marketing/news-post/';
export const POST_EXPOSURE_FILTER_URL = '/marketing/news-post/exposure-filter';
export const AUTHORIZATION_ROLE_URL = '/authorizations/roles';
export const AUTHORIZATION_ROLE_CREATE_URL = '/authorizations/role';
export const AUTHORIZATION_RIGHTS_URL = '/authorizations/rights';
export const STAFF_LIST_URL = '/staffs/list';
export const STAFF_CREATE_URL = '/staffs/create';
export const STAFF_DELETE_URL = '/staffs/delete';
export const STAFF_DETAIL_URL = '/staffs';
export const AUTHORIZATION_RIGHT_URL = '/authorizations/right';
export const MAGAZINES_URL = '/magazines'
